import Layout from './Layout'
import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import userService from '../services/user.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function ResetPassword (props) {
  const [getOtp, setOtp] = useState('')
  const [getPassword, setPassword] = useState('')
  let history = useNavigate()
  const resetpasswordfn = async () => {
    if (
      getOtp === null ||
      getOtp === undefined ||
      getOtp === '' ||
      getPassword === null ||
      getPassword === undefined ||
      getPassword === ''
    ) {
      toast.error('Required All Field!')
    } else {
      try {
        let data = {
          otp: getOtp,
          email: localStorage.getItem('Gmail'),
          new_password: getPassword
        }
        let response = await userService.resetPassword(data)
        if (response.status) {
          localStorage.clear()
          toast.success('password changed successfully')
          setTimeout(() => {
            history('/login')
          }, 2000)
        } else {
          toast.error(response.message)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  return (
    <Layout>
      <ToastContainer />
      <div className='login-bg'>
        <div className='container'>
          <div className='row align-items-center position-relative justify-content-center'>
            <div className='col-lg-5 wow animate__animated animate__fadeInDown  animate__slow'>
              <div className='card-div p-5'>
                <h2 className='text-white fw-bold'>Forgot Password</h2>
                <p className='mt-3'>
                  Tell us you email address,and we'll get you back on track in
                  to time
                </p>
                <div className='i mb-3 form-div'>
                  <label htmlFor='' className=''>
                    Otp <span>*</span>
                  </label>
                  <input
                    onChange={e => {
                      setOtp(e.target.value)
                    }}
                    type='number'
                    className='form-control'
                    placeholder='Otp'
                    aria-label='User Name'
                    aria-describedby='basic-addon2'
                  />
                </div>
                <div className='i mb-3 form-div'>
                  <label htmlFor='' className=''>
                    Password <span>*</span>
                  </label>
                  <input
                    onChange={e => {
                      setPassword(e.target.value)
                    }}
                    type='password'
                    className='form-control'
                    placeholder='Password'
                    aria-label='User Name'
                    aria-describedby='basic-addon2'
                  />
                </div>
                <div className='mt-4'>
                  <Link
                    className='btn-1 w-100 text-center'
                    onClick={resetpasswordfn}
                  >
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ResetPassword
