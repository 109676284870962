import Layout from './Layout'

function TradingRules () {
  return (
    <Layout>
      <div className='inner-page-bg sction-padding'>
        <div className='container section-padding'>
          <h2 className='text-white fw-bold'>Trading Rules</h2>
          <p className='text-secondary mt-2 mb-5'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <div>
            <div className='col-lg-7 faq-grp'>
              <div className='accordion' id='accordionPanelsStayOpenExample'>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#panelsStayOpen-collapseOne'
                      aria-expanded='true'
                      aria-controls='panelsStayOpen-collapseOne'
                    >
                      Can I hold trades during news and over the weekend?
                    </button>
                  </h2>
                  <div
                    id='panelsStayOpen-collapseOne'
                    className='accordion-collapse collapse show'
                  >
                    <div className='accordion-body'>
                      <strong>
                        During our evaluation we have no restrictions regarding
                        news trading and holding…
                      </strong>{' '}
                      It is shown by default, until the collapse plugin adds the
                      appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#panelsStayOpen-collapseTwo'
                      aria-expanded='false'
                      aria-controls='panelsStayOpen-collapseTwo'
                    >
                      Expert Advisor (EA) usage on Evaluation and Master Account
                    </button>
                  </h2>
                  <div
                    id='panelsStayOpen-collapseTwo'
                    className='accordion-collapse collapse'
                  >
                    <div className='accordion-body'>
                      <strong>This is the second item's accordion body.</strong>{' '}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#panelsStayOpen-collapseThree'
                      aria-expanded='false'
                      aria-controls='panelsStayOpen-collapseThree'
                    >
                      How do I get funded by Funding Pips?
                    </button>
                  </h2>
                  <div
                    id='panelsStayOpen-collapseThree'
                    className='accordion-collapse collapse'
                  >
                    <div className='accordion-body'>
                      <strong>This is the third item's accordion body.</strong>{' '}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className='accordion-item mb-0 pb-0'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#panelsStayOpen-collapseFour'
                      aria-expanded='false'
                      aria-controls='panelsStayOpen-collapseFour'
                    >
                      How do I get funded by Funding Pips?
                    </button>
                  </h2>
                  <div
                    id='panelsStayOpen-collapseFour'
                    className='accordion-collapse collapse'
                  >
                    <div className='accordion-body'>
                      <strong>This is the third item's accordion body.</strong>{' '}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TradingRules
