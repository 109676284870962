import img31 from '../assets/images/landing-page/logo-467x100.png'
import { Link } from 'react-router-dom'
function Footer () {
  return (
    <>
      <footer className='section-padding section-primary-bg'>
        <div className='container'>
          <div className='d-block d-md-flex justify-content-between'>
            <div className=''>
              <img src={img31} width='250px' alt='' />
            </div>
            <div className='d-flex align-items-center'>
              {/* <!-- social media begin --> */}
              <div className='d-flex social-media-list'>
                <div>
                  <Link className='text-white'>
                    <i className='fab fa-facebook-square'></i>{' '}
                  </Link>
                </div>
                <div>
                  <Link className='text-white ms-2'>
                    <i className='fab fa-twitter'></i>{' '}
                  </Link>
                </div>
                <div>
                  <Link className='text-white ms-2'>
                    <i className='fab fa-instagram'></i>{' '}
                  </Link>
                </div>
                <div>
                  <Link className='text-white ms-2'>
                    <i className='fab fa-telegram'></i>{' '}
                  </Link>
                </div>
                <div>
                  <Link className='text-white ms-2'>
                    <i className='fab fa-youtube'></i>{' '}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <h5 className='theme-primary-text fw-bold'>Markets</h5>
              <ul className='list-unstyled footer-list mt-3'>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Share CFDs
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Forex
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Indices
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Commodities
                  </Link>
                </li>
              </ul>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <h5 className='theme-primary-text fw-bold'>Trading Platforms</h5>
              <ul className='list-unstyled footer-list mt-3'>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Web platform
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Trading apps
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary '>
                    Match Trader
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Compare features
                  </Link>
                </li>
              </ul>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <h5 className='theme-primary-text fw-bold'>Account Types</h5>
              <ul className='list-unstyled footer-list mt-3'>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Stimulated accounts
                  </Link>
                </li>
                {/* <li>
                  <Link className='text-decoration-none text-secondary'>
                    Demo account
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary '>
                    Standart account
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    ECN account
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Islamic acount
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <h5 className='theme-primary-text fw-bold'>Learn to Trade</h5>
              <ul className='list-unstyled footer-list mt-3'>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    News and trade ideas
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Trading strategy
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none text-secondary'>
                    Forex trading course
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='mt-4'>
            <div className=''>
              <p className='copyright'>
                <span>Copyright ©2023. All Rights Reserved.</span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
