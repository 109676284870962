import React, { useState, useEffect } from 'react'
import SubHeader from './SubHeader'
import Layout from './Layout'
import '../assets/ListAndAdd.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NavLink } from 'react-router-dom'
import {useApi} from '../hook/useApi'

const Payout = () => {
  const [requestHit] = useApi();
  const [accountList, setAccountList] = useState([])
  // const [action, setAction] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState('0')
  const [paymentStatus] = useState(['Pending', 'Credited', 'Rejected'])
  const [tradingHistory, setTradingHistory] = useState([])
  const [payloadsNew, setPayloadsNew] = useState({})
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [deletepopup, setdeletepopup] = useState(false)

  const updatedResponse = async () => {}  

  useEffect(() => {
    requestHit('get', 'user/get-payouts').then(response => {
      if (response.status) {
        setTradingHistory(response.data)
      }
    })
    requestHit('get', 'user/get-challenge').then(response => {
      if (response.status) {
        if (response.status) {
          setAccountList(response.data)
        } else {
          setAccountList({})
        }
      }
    })
  }, [])
  //payloadsNew, tradingHistory

  const handleInputChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    setPayloadsNew(pres => {
      return {
        ...pres,
        [name]: value
      }
    })
  }

  const openPopup = e => {
    e.preventDefault()
    setPopupOpen(true)
  }
  const editfunction = (e, props) => {
    let demo = {}
    Object.keys(props).reduce((acc, key) => {
      demo[key] = props[key]
      return acc
    }, {})

    demo.acc_no
      ? setPaymentMethod('0')
      : demo.upi_id
      ? setPaymentMethod('1')
      : setPaymentMethod('0')
    setPayloadsNew(demo)
    setPopupOpen(true)
  }
  const deletefunction = (e, props) => {
    setdeletepopup(true)
    let demo = {}
    Object.keys(props).reduce((acc, key) => {
      demo[key] = props[key]
      return acc
    }, {})
    setPayloadsNew(demo)
  }
  const closePopup = () => {
    setPopupOpen(false)
    setdeletepopup(false)
  }
  const commonApi = async (e, paramscheck) => {
    e.preventDefault()
    try {
      var response
      if (paramscheck === 'delete') {
        response = await requestHit('post', 'user/delete-payout', payloadsNew)
      } else if (paramscheck === 'edit') {
        response = await requestHit('post', 'user/update-payout', payloadsNew)
      } else if (paramscheck === 'add') {
        response = await requestHit('post', 'user/create-payout', payloadsNew)
      }
      if (response.status) {
        toast.success(response.message)
        updatedResponse()
        closePopup()
      } else {
        toast.error(response.message)
        closePopup()
      }
    } catch (error) {
      toast.error(error.message)
      closePopup()
    }
  }

  return (
    <>
      {localStorage.getItem('token') !== null ||
      localStorage.getItem('token') !== undefined ? (
        <Layout>
          <div className='container-fluid  inner-page-bg'>
            <div className='row'>
            <SubHeader />
              <div className='col-lg-9 mt-4'>
                {/* <ul className="pb-0 ms-0 list-unstyled">
                  <li className='card-div certificate-div'>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <img
                          src={require('../assets/images/certificate.png')}
                          alt='author'
                          className='img-fluid'
                        />
                      </div>
                      <div className='col-lg-8'>
                          <h4>Certificate Download</h4>
                          <p>Touch and hold what you want to download, then tap Download link or Download image. On some video and audio files</p>
                          <div className="download-btn">
                            <a>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" className="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0"/>
                              </svg>
                            </a>
                          </div>
                      </div>
                    </div>
                  </li>
                  <li className='card-div certificate-div mt-4'>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <img
                          src={require('../assets/images/certificate.png')}
                          alt='author'
                          className='img-fluid'
                        />
                      </div>
                      <div className='col-lg-8'>
                          <h4>Certificate Download</h4>
                          <p>Touch and hold what you want to download, then tap Download link or Download image. On some video and audio files</p>
                          <div className="download-btn">
                            <a>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" className="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0"/>
                              </svg>
                            </a>
                          </div>
                      </div>
                    </div>
                  </li>
                  <li className='card-div certificate-div mt-4'>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <img
                          src={require('../assets/images/certificate.png')}
                          alt='author'
                          className='img-fluid'
                        />
                      </div>
                      <div className='col-lg-8'>
                          <h4>Certificate Download</h4>
                          <p>Touch and hold what you want to download, then tap Download link or Download image. On some video and audio files</p>
                          <div className="download-btn">
                            <a>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" className="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0"/>
                              </svg>
                            </a>
                          </div>
                      </div>
                    </div>
                  </li>
                  <li className='card-div certificate-div mt-4'>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <img
                          src={require('../assets/images/certificate.png')}
                          alt='author'
                          className='img-fluid'
                        />
                      </div>
                      <div className='col-lg-8'>
                          <h4>Certificate Download</h4>
                          <p>Touch and hold what you want to download, then tap Download link or Download image. On some video and audio files</p>
                          <div className="download-btn">
                            <a>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" className="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0"/>
                              </svg>
                            </a>
                          </div>
                      </div>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>
          

           
          </div>
        </Layout>
      ) : (
        <>
          <NavLink to='/login' />
        </>
      )}
    </>
  )
}

export default Payout
