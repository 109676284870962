import React, { useState, useEffect } from 'react'
import SubHeader from './SubHeader'
import Layout from './Layout'
import '../assets/ListAndAdd.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NavLink } from 'react-router-dom'
import {useApi} from '../hook/useApi'

const Payout = () => {
  const [requestHit] = useApi();
  const [accountList, setAccountList] = useState([])
  // const [action, setAction] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState('0')
  const [paymentStatus] = useState(['Pending', 'Credited', 'Rejected'])
  const [tradingHistory, setTradingHistory] = useState([])
  const [payloadsNew, setPayloadsNew] = useState({})
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [deletepopup, setdeletepopup] = useState(false)

  const updatedResponse = async () => {}  

  useEffect(() => {
    requestHit('get', 'user/get-payouts').then(response => {
      if (response.status) {
        setTradingHistory(response.data)
      }
    })
    requestHit('get', 'user/get-challenge').then(response => {
      if (response.status) {
        if (response.status) {
          setAccountList(response.data)
        } else {
          setAccountList({})
        }
      }
    })
  }, [])
  //payloadsNew, tradingHistory

  const handleInputChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    setPayloadsNew(pres => {
      return {
        ...pres,
        [name]: value
      }
    })
  }

  const openPopup = e => {
    e.preventDefault()
    setPopupOpen(true)
  }
  const editfunction = (e, props) => {
    let demo = {}
    Object.keys(props).reduce((acc, key) => {
      demo[key] = props[key]
      return acc
    }, {})

    demo.acc_no
      ? setPaymentMethod('0')
      : demo.upi_id
      ? setPaymentMethod('1')
      : setPaymentMethod('0')
    setPayloadsNew(demo)
    setPopupOpen(true)
  }
  const deletefunction = (e, props) => {
    setdeletepopup(true)
    let demo = {}
    Object.keys(props).reduce((acc, key) => {
      demo[key] = props[key]
      return acc
    }, {})
    setPayloadsNew(demo)
  }
  const closePopup = () => {
    setPopupOpen(false)
    setdeletepopup(false)
  }
  const commonApi = async (e, paramscheck) => {
    e.preventDefault()
    try {
      var response
      if (paramscheck === 'delete') {
        response = await requestHit('post', 'user/delete-payout', payloadsNew)
      } else if (paramscheck === 'edit') {
        response = await requestHit('post', 'user/update-payout', payloadsNew)
      } else if (paramscheck === 'add') {
        response = await requestHit('post', 'user/create-payout', payloadsNew)
      }
      if (response.status) {
        toast.success(response.message)
        updatedResponse()
        closePopup()
      } else {
        toast.error(response.message)
        closePopup()
      }
    } catch (error) {
      toast.error(error.message)
      closePopup()
    }
  }

  return (
    <>
      {localStorage.getItem('token') !== null ||
      localStorage.getItem('token') !== undefined ? (
        <Layout>
          <div className='container-fluid  inner-page-bg'>
            <div className='row'>
            <SubHeader />
              <div className='col-lg-9 h-500'>
                <button
                  onClick={e => {
                    openPopup(e)
                  }}
                  className='primary-btn btn-sm float-sm-end float-md-end float-lg-end my-4'
                >
                  Add Item
                </button>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {tradingHistory.length > 0 ? (
                  <>
                    <div className='dashbord-table table-responsive card-div h-100'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th scope='col'>S No</th>
                            <th scope='col'>Login</th>
                            <th scope='col'>Payout</th>
                            <th scope='col'>Amount</th>
                            <th scope='col'>Status</th>
                            <th scope='col'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tradingHistory.map((props, index) => (
                            <tr key={index}>
                              <td style={{ color: 'black' }}>{index + 1}</td>
                              <td>{props.login}</td>
                              {props.upi_id ? (
                                <td>UPI : {props.upi_id}</td>
                              ) : (
                                <td>
                                  Account : {props.acc_no}
                                  <br />
                                  {props.ifsc_code}
                                  <br />
                                  {props.micr_code}
                                </td>
                              )}
                              <td>{props.amount}</td>
                              <td>{paymentStatus[props.payment_status]}</td>
                              <td>
                                <button
                                  className='btn btn-primary mx-2'
                                  onClick={e => {
                                    editfunction(e, props)
                                  }}
                                >
                                  {' '}
                                  Edit{' '}
                                </button>
                                <button
                                  className='btn btn-danger mx-2'
                                  onClick={e => {
                                    deletefunction(e, props)
                                  }}
                                >
                                  {' '}
                                  Delete{' '}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div className='row justify-content-between'>
                     <div className='col-lg-8'>
                       <div className='text-white no-record-position'>No Record Found</div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {isPopupOpen && (
              <div className='popup-overlay'>
                <div className='col-lg-9 popup card-div form-div'>
                  <div className='row'>
                    <div className='col-lg-4 col-md-6 my-2'>
                      <select
                        id='dropdown'
                        className='w-100 h-100'
                        name='login'
                        value={payloadsNew.login}
                        onChange={e => {
                          handleInputChange(e)
                        }}
                      >
                        <option value=''>Select Account</option>
                        {accountList.map(option => (
                          <option key={option.login} value={option.login}>
                            {option.login} - Profit : {option.profit}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className='col-lg-4 col-md-6 my-2'>
                      <select
                        id='dropdown'
                        className='w-100 h-100'
                        value={paymentMethod}
                        onChange={e => {
                          setPaymentMethod(e.target.value)
                        }}
                      >
                        <option value=''>Select Payment Method</option>
                        <option value='0'>Via Wire Payment</option>
                        <option value='1'>Via UPI</option>
                      </select>
                    </div>

                    {paymentMethod === '1' ? (
                      <>
                        <div className='col-lg-4 col-md-6 my-2'>
                          <input
                            type='text'
                            id='upi_id'
                            name='upi_id'
                            value={payloadsNew.upi_id}
                            className='w-100 h-100'
                            placeholder='Enter UPI ID'
                            onChange={e => {
                              handleInputChange(e)
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='col-lg-4 col-md-6 my-2'>
                          <input
                            type='number'
                            id='itemName'
                            name='acc_no'
                            value={payloadsNew.acc_no}
                            className='w-100 h-100'
                            placeholder='Enter Account Number'
                            onChange={e => {
                              handleInputChange(e)
                            }}
                          />
                        </div>

                        <div className='col-lg-4 col-md-6 my-2'>
                          <input
                            type='text'
                            id='itemName'
                            name='ifsc_code'
                            value={payloadsNew.ifsc_code}
                            className='w-100 h-100'
                            placeholder='Enter IFSC Code'
                            onChange={e => {
                              handleInputChange(e)
                            }}
                          />
                        </div>

                        <div className='col-lg-4 col-md-6 my-2'>
                          <input
                            type='text'
                            id='itemName'
                            name='micr_code'
                            value={payloadsNew.micr_code}
                            className='w-100 h-100'
                            placeholder='Enter MICR Code'
                            onChange={e => {
                              handleInputChange(e)
                            }}
                          />
                        </div>
                      </>
                    )}

                    <div className='col-lg-4 col-md-6 my-2'>
                      <input
                        type='number'
                        id='itemName'
                        name='amount'
                        value={payloadsNew.amount}
                        className='w-100 h-100'
                        placeholder='Enter Amount'
                        onChange={e => {
                          handleInputChange(e)
                        }}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 button-container'>
                      {payloadsNew._id ? (
                        <button
                          onClick={e => commonApi(e, 'edit')}
                          className='add-button'
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          onClick={e => commonApi(e, 'add')}
                          className='add-button'
                        >
                          Request
                        </button>
                      )}

                      <button onClick={closePopup} className='cancel-button'>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {deletepopup && (
              <div className='popup-overlay'>
                <div className='popup'>
                  <div>
                    <p className='text-black'>
                      Are you sure want to remove this details ?
                    </p>
                  </div>
                  <div className='button-container'>
                    <button
                      onClick={e => commonApi(e, 'delete')}
                      className='add-button'
                    >
                      Delete
                    </button>
                    <button onClick={closePopup} className='cancel-button'>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Layout>
      ) : (
        <>
          <NavLink to='/login' />
        </>
      )}
    </>
  )
}

export default Payout
