import React from 'react'
import {
  Route,
  Routes
} from 'react-router-dom'
import LandingPages from './components/LandingPages'
import Register from './components/Register'
import Login from './components/Login'
import ContactUs from './components/ContactUs'
import Faq from './components/Faq'
import TradingRules from './components/TradingRules'
import Dashboard from './components/Dashboard'
import './assets/animate.min.css'
import ForgotPassword from './components/ForgotPassword'
import Otp from './components/Otp'
import ResetPassword from './components/ResetPassword'
import NewFrame from './components/NwFrame'
import Account from './components/Account'
import SingleFaq from './components/SingleFaq'
import SettingsPage from './components/Setting'
import Payout from './components/Payout'
import Certificte from './components/Certificate'
import KycStepOne from './components/KycStepOne'
import KycStepTwo from './components/KycStepTwo'
import KycStepThree from './components/KycStepThree'
import InVoice from './components/invoice'
import Blog from './components/Blog'
import { Provider } from 'react-redux';
import { store }  from './stores/global';
import Loading from './components/Loading'

function App () {
  return (
    <Provider store={store}>      
      <Loading />
      <Routes>
        <Route exact path='/' element={<LandingPages />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/register' element={<Register />} />
        <Route exact path='/forgotpassword' element={<ForgotPassword />} />
        <Route exact path='/resetpassword' element={<ResetPassword />} />
        <Route exact path='/otp' element={<Otp />} />
        <Route exact path='/contactus' element={<ContactUs />} />
        <Route exact path='/faq' element={<Faq />} />
        <Route exact path='/trading_rules' element={<TradingRules />} />
        <Route exact path='/dashboard' element={<Dashboard />} />
        <Route exact path='/dashboard/:id' element={<Dashboard />} />
        <Route exact path='/accountdetails' element={<Account />} />
        <Route exact path='/newchallenge' element={<NewFrame />} />
        <Route exact path='/singlefaq' element={<SingleFaq />} />
        <Route exact path='/acc_setting' element={<SettingsPage />} />
        <Route exact path='/payout' element={<Payout />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path='/certificate' element={<Certificte />} />
        <Route exact path='/kycstepone' element={<KycStepOne />} />
        <Route exact path='/kycsteptwo' element={<KycStepTwo />} />
        <Route exact path='/kycstepthree' element={<KycStepThree />} />
        <Route exact path='/invoice' element={<InVoice />} />
      </Routes>
    </Provider>
  )
}

export default App
