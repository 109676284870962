import axios from 'axios'
import http_common from '../http_common'
const cmsresponse = async () => {
  try {
    let response = await axios.get(`${http_common}admin/get-cms/2`)
    console.log('APiresponse', response.data)
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}
const faqresponse = async () => {
  try {
    let response = await axios.get(
      `${http_common}user/get-faq/652eaf8d539c10888d47c271`
    )
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}

const contactresponse = async data => {
  try {
    let response = await axios.post(`${http_common}user/create-contactus`, data)
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}

const cms = { faqresponse, contactresponse, cmsresponse }

export default cms
