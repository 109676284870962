import React from 'react';
import '../assets/progress.css'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChart({dataObj}) {
  return (
    <div className='doughnut-chat-master'>
      { dataObj && (
        <>
          <Doughnut data={dataObj} />
          <label>{dataObj.datasets[0].data[0]} %</label>
        </>
      )}
     
    </div>
  );
}
