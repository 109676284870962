import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import giftest from "../assets/images/landing-page/header-element/pngwing.com.png";
import gif1 from "../assets/images/landing-page/header-element/header-gif.gif";
import img1 from "../assets/images/landing-page/header-element/element-01.png";
import img2 from "../assets/images/landing-page/header-element/element-02.png";
import img9 from "../assets/images/landing-page/services/first/ellipse-1.png";
import img13 from "../assets/images/landing-page/services/second/ellipse-1.png";
import img17 from "../assets/images/landing-page/services/third/ellipse-1.png";
import img20 from "../assets/images/landing-page/Icons/1.png";
import img21 from "../assets/images/landing-page/Icons/2.png";
import img22 from "../assets/images/landing-page/Icons/3.png";
import img23 from "../assets/images/landing-page/Icons/4.png";
import img24 from "../assets/images/landing-page/laptop.png";
import img25 from "../assets/images/landing-page/payment_icons/1.png";
// import img26 from '../assets/images/landing-page/payment_icons/2.png'
import img27 from "../assets/images/landing-page/payment_icons/3.png";
import img28 from "../assets/images/landing-page/payment_icons/4.png";
import img29 from "../assets/images/landing-page/payment_icons/5.png";
import img30 from "../assets/images/landing-page/payment_icons/6.png";
import best1 from "../assets/images/best-in-the-industry/1.png";
import best2 from "../assets/images/best-in-the-industry/2.png";
import best3 from "../assets/images/best-in-the-industry/3.png";
import best4 from "../assets/images/best-in-the-industry/4.png";
import best5 from "../assets/images/best-in-the-industry/5.png";
import best6 from "../assets/images/best-in-the-industry/6.png";
import best7 from "../assets/images/best-in-the-industry/7.png";
import best8 from "../assets/images/best-in-the-industry/8.png";
import best9 from "../assets/images/best-in-the-industry/9.png";
import best10 from "../assets/images/best-in-the-industry/10.png";
import best11 from "../assets/images/best-in-the-industry/11.png";
import best12 from "../assets/images/best-in-the-industry/12.png";
import Layout from "./Layout";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Blog from "./Blog";

const TextAnimation = ({ text, interval }) => {
  const [animatedText, setAnimatedText] = useState("");

  useEffect(() => {
    let index = 0;
    let savedText = "";
    const intervalId = setInterval(() => {
      const newAnimatedText = savedText + text[index];
      setAnimatedText(newAnimatedText);
      savedText = newAnimatedText;
      index++;
      if (index === text.length) {
        savedText = "";
        index = 0;
        // clearInterval(intervalId);
      }
    }, interval);

    return () => clearInterval(intervalId);
  }, [text, interval]);

  return <div>{animatedText}</div>;
};

function App() {
  return (
    <Layout>
      <div className="header-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 wow animate__animated animate__fadeInDown order-2 order-lg-1 text-center text-lg-start  animate__slow">
              <h1>
                Trade with Confidence, Trade with{" "}
                <TextAnimation text="Global FX Funders" interval={100} />
              </h1>
              <p className="mt-3 header-para">
                The Best Prop Firm with No Hidden Rules.
              </p>
              <div className="mt-4">
                <button className="header-btn" to="/newchallenge">
                  <Link
                    to="/newchallenge"
                    className="text-decoration-none text-white"
                  >
                    Get funded
                  </Link>
                </button>
                <button className="ms-3 btn-1 trans-bg" to="/newchallenge">
                  <Link
                    to="/newchallenge"
                    className="text-decoration-none text-white"
                  >
                    Join Discord
                  </Link>
                </button>
              </div>
            </div>
            <div className="col-lg-6 text-center order-1 order-lg-2">
              <div className="position-relative header-element">
                <img src={giftest} alt="" className="img-fluid" />
                {/* <img src={gif1} alt='' className='img-fluid' /> */}
                {/* <div className='position-absolute position-lg-absolute top-0 bottom-0 start-0 end-0'>
                  <img
                    src={img1}
                    alt=''
                    className='img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  />
                </div>
                <div className='position-absolute position-lg-absolute top-0 bottom-0 start-0 end-0'>
                  <img
                    src={img2}
                    alt=''
                    className='img-fluid  wow animate__animated animate__pulse animate__delay-3s  animate__slow animate__infinite'
                  />
                </div> */}
              </div>
              {/* <!-- <img src="./assets/images/landing-page/header-element.png" alt=""> --> */}
            </div>
          </div>
        </div>
      </div>

      {/* <!--============= Header Banner End ============-->
  <!--============= Other Logos Start ============--> */}
      {/* <div className='section-padding section-marquee-bg'>
        <div className='container'>
          <section className='d-flex align-items-center enable-animation'>
            <div className='marquee'>
              <ul className='marquee__content list-unstyled mb-0'>
                <li className=''>
                  
                  <div>
                    <h6 className='text-white'>
                      <span>USE CODE </span>
                      <span className='theme-primary-text '>NOVEMBER2023</span>{' '}
                    </h6>
                  </div>
                  <div>
                    {' '}
                    <h6 className='text-white'>
                      {' '}
                      FOR<span className='theme-primary-text '> 40% OFF!</span>
                    </h6>
                  </div>
                </li>
                <li className='text-left'>
                  
                  <div>
                    <h6 className='text-white'>
                      <span>USE CODE </span>
                      <span className='theme-primary-text '>NOVEMBER2023</span>{' '}
                    </h6>
                  </div>
                  <div>
                    {' '}
                    <h6 className='text-white'>
                      {' '}
                      FOR<span className='theme-primary-text '> 40% OFF!</span>
                    </h6>
                  </div>
                </li>
                <li className='text-left'>
                  
                  <div>
                    <h6 className='text-white'>
                      <span>USE CODE </span>
                      <span className='theme-primary-text '>NOVEMBER2023</span>{' '}
                    </h6>
                  </div>
                  <div>
                    {' '}
                    <h6 className='text-white'>
                      {' '}
                      FOR<span className='theme-primary-text '> 40% OFF!</span>
                    </h6>
                  </div>
                </li>
                <li className='text-left'>
                 
                  <div>
                    <h6 className='text-white'>
                      <span>USE CODE </span>
                      <span className='theme-primary-text '>NOVEMBER2023</span>{' '}
                    </h6>
                  </div>
                  <div>
                    {' '}
                    <h6 className='text-white'>
                      {' '}
                      FOR<span className='theme-primary-text '> 40% OFF!</span>
                    </h6>
                  </div>
                </li>
                <li className='text-left'>
                  
                  <div>
                    <h6 className='text-white'>
                      <span>USE CODE </span>
                      <span className='theme-primary-text '>NOVEMBER2023</span>{' '}
                    </h6>
                  </div>
                  <div>
                    {' '}
                    <h6 className='text-white'>
                      {' '}
                      FOR<span className='theme-primary-text '> 40% OFF!</span>
                    </h6>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div> */}
      {/* <!--============= Other Logos End ============-->
  <!--============= Services Start ============--> */}

      <section className="section-padding section-primary-bg ">
        <div className="container">
          <div className="tilte-header row justify-content-between">
            <div className="col-lg-5">
              <h2>
                Why we are one of <span>the Best</span> In The Industry.
              </h2>
            </div>

            <div className="col-lg-7 text-end">
              <button className="btn-1 trans-bg" to="/newchallenge">
                <Link
                  to="/newchallenge"
                  className="text-decoration-none text-white"
                >
                  {" "}
                  Start Now
                </Link>

                <i className="fas fa-angle-right uk-margin-small-left ms-2"></i>
              </button>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 wow animate__animated animate__fadeInLeft  animate__slow">
              <div className="card-div h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img8}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img9}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  {/* <div className='position-static position-md-absolute top-0 bottom-0 start-0 end-0'>
                    <img
                      src={best1}
                      alt='ellipse'
                      className='img-fluid wow animate__animated animate__zoomInDown  animate__slow '
                    />
                  </div> */}
                  <div>
                    <img
                      src={best1}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown  animate__slow "
                    />
                  </div>
                </div>

                <div className="services-card-body">
                  <h4>Fast Payouts</h4>
                  <p>
                    At GFF, we pride ourselves on offering fast payouts,
                    processing directly after requesting on payout date.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 mt-lg-0">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img12}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img13}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best2}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4>Weekly Payout Available</h4>
                  <p>
                    Why wait 30 days for your profits? With our addon, you can
                    withdraw your earnings every week! Enjoy the flexibility and
                    control you deserve.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4 mt-lg-0">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img16}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img17}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best3}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4>12% Maximum Drawdown Available</h4>
                  <p>
                    Leave behind those rigid 8% drawdown limits that cramp your
                    style. At GFF, our industry-leading 12% maximum drawdown
                    accelerates your path to funding and ensures a stress-free
                    journey from the fear of breaching.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img8}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s    animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img9}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best4}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4>Receive up to 95% Payout Split</h4>
                  <p>
                    Your success, your rewards. Experience the freedom of
                    trading with our exceptional profit-sharing model, where you
                    can earn up to a remarkable 95% profit split at GFF. Your
                    success story begins here.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img12}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img13}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best5}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>

                <div className="services-card-body">
                  <h4>No StopLoss Rule </h4>
                  <p>
                    At GFF, we respect your trading autonomy. We don't impose
                    any mandatory stop-loss rules. You have the freedom to
                    manage your account the way you choose.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img16}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img17}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best6}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4>News Trading Allowed -</h4>
                  <p>
                    - News trading is welcomed here. At GFF, we support your
                    news trading strategies, providing you with the flexibility
                    you need for success in the market.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img8}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img9}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best7}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4>Overnight & Weekend holding Allowed</h4>
                  <p>
                    - Hold with confidence! At GFF, we impose no restrictions on
                    holding your positions overnight or over weekends. Your
                    trading decisions, your way.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img12}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img13}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best8}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4>Unlimited Trading Days</h4>
                  <p>
                    - Enjoy a stress-free journey towards success. At GFF, you
                    have the freedom of unlimited trading days to complete the
                    challenge at your own pace, ensuring you can trade with
                    confidence and patience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img16}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img17}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best9}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4>No Consistency Rule</h4>
                  <p>
                    At GFF, there are no consistency rules to restrict your
                    trading style. We empower you with the freedom to trade on
                    your terms, embracing diversity in trading strategies.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img8}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img9}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best10}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4>Retry by taking a New Challenge</h4>
                  <p>
                    - Unlock a second chance and save up to 30%! If you breach
                    your account, you can retry by taking a new challenge with
                    our generous discount offer.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img12}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img13}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best11}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4>Immediately Fees Refund</h4>
                  <p>
                    - Instant fee refund with your very first payout. We
                    prioritize your success and offer a seamless experience by
                    refunding fees right away.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__fadeInRight   animate__slow mt-4">
              <div className="card-div  h-100">
                <div className="service-img-hover position-relative">
                  {/* <img
                    src={img16}
                    alt='ellipse'
                    className='ser-ellispe d-none d-lg-block img-fluid  wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite'
                  /> */}
                  <img
                    src={img17}
                    alt="ellipse"
                    className="ser-ellispe-1 img-fluid wow animate__animated animate__pulse animate__delay-2s  animate__slow animate__infinite"
                  />
                  <div>
                    <img
                      src={best12}
                      alt="ellipse"
                      className="img-fluid wow animate__animated animate__zoomInDown   animate__slow "
                    />
                  </div>
                </div>
                <div className="services-card-body">
                  <h4> Best Scaling Plan</h4>
                  <p>
                    Unlock the best scaling plan for consistent success. When
                    you maintain profitability each month, our scaling plan will
                    significantly enhance your account balance
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= Services End ============-->
       <!--============= Phase Start ============--> */}
      <section className="section-padding section-primary-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 wow animate__animated animate__zoomIn  animate__slower phase-grp">
              <div className="circle-2"></div>
              <div className="card-effect h-100">
                <h4 className="text-white"> Phase 1</h4>
                <p className="mt-3">
                  Phase 1 is your first step in the evaluation process. To move
                  on to Phase 2, prove your trading skills and discipline by
                  meeting the Trading Objectives, with no time pressure
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__zoomIn  animate__slower mt-4 mt-lg-0 phase-grp">
              <div className="circle-1"></div>
              <div className="card-effect h-100">
                <h4 className="text-white">Phase 2</h4>
                <p className="mt-3">
                  Phase 2, the final step in your journey to becoming a Funded
                  Trader, is just like Phase 1 – no time limits. After
                  successfully completing this stage and verifying your results,
                  you'll earn your place as a Funded Trader with us!
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow animate__animated animate__zoomIn  animate__slower mt-4 mt-lg-0 phase-grp">
              <div className="circle-2"></div>
              <div className="card-effect h-100">
                <h4 className="text-white"> Funded Trader</h4>
                <p className="mt-3">
                  Congratulations, you've achieved Funded Trader status at
                  Global FX Funders! Trade responsibly, and you can enjoy
                  earning up to 95% of your profits. Plus, for sustained
                  profitability each month, we'll boost your account balance
                  through our scaling plan
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= Phase End ============-->
   <!--============= Table Start ============--> */}

      <section className="section-padding section-primary-bg d-none">
        <div className="container">
          <div className="tilte-header row justify-content-between">
            <div className="col-lg-12">
              <h2>
                Pick your challenge,show your effective{" "}
                <span>
                  trading strategies, and create substantial virtual profits
                </span>{" "}
                to build your trading future
              </h2>
            </div>
          </div>
          <div className="row mt-5 position-relative d-lg-block d-none">
            <div className="col-lg-6 text-end">
              <ul
                className="tabbed-table nav nav-pills mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Regular
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Super
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-lg-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active table-responsive"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex="0"
                >
                  <table className="table primary-table">
                    <thead>
                      <tr className="table-heading">
                        <th scope="col">Account Size</th>
                        <th scope="col">$5k</th>
                        <th scope="col">$10k</th>
                        <th scope="col">$20k</th>
                        <th scope="col">$50k</th>
                        <th scope="col">$100k</th>
                        <th scope="col">$200k</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Phase 1 Target
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>8%</td>
                        <td>8</td>
                        <td>8</td>
                        <td>8</td>
                        <td>8</td>
                        <td>8</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_1"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Phase 2<i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_1">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_2"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Maximum Daily Loss{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_2">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>5%</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_3"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Maximum OverAll Loss{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_3">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_3"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Drawdown Type <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_3">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_4"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Time Limit <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_4">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>No Limit</td>
                        <td>No Limit</td>
                        <td>No Limit</td>
                        <td>No Limit</td>
                        <td>No Limit</td>
                        <td>No Limit</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_5"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Minimum Trading Days{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_5">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_6"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Profit Split <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_6">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_7"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Trading Leverage{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_7">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>1:100</td>
                        <td>1:100</td>
                        <td>1:100</td>
                        <td>1:100</td>
                        <td>1:100</td>
                        <td>1:100</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_8"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              News Trading <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_8">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_9"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Weekend Holding{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_9">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_10"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Refundable Fee
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_10">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>$49</td>
                        <td>$89</td>
                        <td>$149</td>
                        <td>$279</td>
                        <td>$499</td>
                        <td>$949</td>
                      </tr>
                      <tr>
                        <th scope="row">Choose Desierd Plan</th>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="tab-pane fade table-responsive"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex="1"
                >
                  <table className="table primary-table">
                    <thead>
                      <tr className="table-heading">
                        <th scope="col">Account Size</th>
                        <th scope="col">$5k</th>
                        <th scope="col">$10k</th>
                        <th scope="col">$20k</th>
                        <th scope="col">$50k</th>
                        <th scope="col">$100k</th>
                        <th scope="col">$200k</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Phase 1 Target
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>6%</td>
                        <td>6%</td>
                        <td>6%</td>
                        <td>6%</td>
                        <td>6%</td>
                        <td>6%</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_1"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Phase 2<i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_1">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>4%</td>
                        <td>4%</td>
                        <td>4%</td>
                        <td>4%</td>
                        <td>4%</td>
                        <td>4%</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_2"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Maximum Daily Loss{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_2">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>5%</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_3"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Maximum OverAll Loss{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_3">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                        <td>10% 12% with addon</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_3"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Drawdown Type <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_3">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                        <td>Balaned Based</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_4"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Time Limit <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_4">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>No Limit</td>
                        <td>No Limit</td>
                        <td>No Limit</td>
                        <td>No Limit</td>
                        <td>No Limit</td>
                        <td>No Limit</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_5"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Minimum Trading Days{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_5">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_6"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Profit Split <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_6">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                        <td>80% 95% with addon</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_7"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Trading Leverage{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_7">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>1:100</td>
                        <td>1:100</td>
                        <td>1:100</td>
                        <td>1:100</td>
                        <td>1:100</td>
                        <td>1:100</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_8"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              News Trading <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_8">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_9"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Weekend Holding{" "}
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_9">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                        <td>Allowed</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <p className="d-inline-flex gap-1">
                            <a
                              className=""
                              data-bs-toggle="collapse"
                              href="#collapseExample_10"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Refundable Fee
                              <i className="bi bi-caret-down"></i>
                            </a>
                          </p>
                          {/* <div className="collapse position-absolute" id="collapseExample_10">
                            <div className="card card-body">
                              FundedNext is the only prop firm to offer a 15% profit sharing from the profit you make during the challenge phases. This is to incentivize our top traders and to deliver on our promise of the world’s best payout bonuses
                            </div>
                          </div> */}
                        </th>
                        <td>$49</td>
                        <td>$89</td>
                        <td>$149</td>
                        <td>$279</td>
                        <td>$499</td>
                        <td>$949</td>
                      </tr>
                      <tr>
                        <th scope="row">Choose Desierd Plan</th>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button className="primary-btn">Select Plan</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* fgdfdf */}

          <div className="tabbed-table-mob d-lg-none d-block mt-3">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item m-2" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-mbtreg-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-mbtreg"
                  type="button"
                  role="tab"
                  aria-controls="pills-mbtreg"
                  aria-selected="true"
                >
                  Regular
                </button>
              </li>
              <li className="nav-item m-2" role="presentation">
                <button
                  className="nav-link"
                  id="pills-mbtsup-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-mbtsup"
                  type="button"
                  role="tab"
                  aria-controls="pills-mbtsup"
                  aria-selected="false"
                >
                  Super
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-mbtreg"
                role="tabpanel"
                aria-labelledby="pills-mbtreg-tab"
                tabIndex="0"
              >
                {/* Regular */}
                <div className="d-block mob-mode-table">
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <span className="m-2">5K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">8%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">5</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>

                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2"> $49</span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                          >
                            <span className="m-2">10K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">8%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">5</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>

                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2"> $89</span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="true"
                            aria-controls="collapseThree"
                          >
                            <span className="m-2">20K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">8%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">5</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    $149
                                  </span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="true"
                            aria-controls="collapseFour"
                          >
                            <span className="m-2">50K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">8%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">5</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    $279
                                  </span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="true"
                            aria-controls="collapseFive"
                          >
                            <span className="m-2">100K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">8%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">5</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    $499
                                  </span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="true"
                            aria-controls="collapseSix"
                          >
                            <span className="m-2">200K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">8%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">5</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    $949
                                  </span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-mbtsup"
                role="tabpanel"
                aria-labelledby="pills-mbtsup-tab"
                tabIndex="0"
              >
                {/* super */}
                <div className="d-block mob-mode-table">
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <span className="m-2">5K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">6%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">4%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>

                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2"> $49</span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                          >
                            <span className="m-2">10K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">6%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">4%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>

                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2"> $89</span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="true"
                            aria-controls="collapseThree"
                          >
                            <span className="m-2">20K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">6%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">4%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    $149
                                  </span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="true"
                            aria-controls="collapseFour"
                          >
                            <span className="m-2">50K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">6%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">4%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    $279
                                  </span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="true"
                            aria-controls="collapseFive"
                          >
                            <span className="m-2">100K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">6%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">4%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    $499
                                  </span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block m-2">
                    <div className="accordion d-flex" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button bg-transparent collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="true"
                            aria-controls="collapseSix"
                          >
                            <span className="m-2">200K</span>
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="card border-0 card-body d-inline-block">
                              <ul className="ps-0 mb-0 list-style-none">
                                <li>
                                  <span className="me-2">Phase 1 Target</span>:
                                  <span className="fw-semibold ms-2">6%</span>
                                </li>
                                <li>
                                  <span className="me-2">Phase 2</span>:
                                  <span className="fw-semibold ms-2">4%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum Daily Loss
                                  </span>
                                  :<span className="fw-semibold ms-2">5%</span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Maximum OverAll Loss
                                  </span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    10% 12% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Drawdown Type</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Balaned Based
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Time Limit</span>:
                                  <span className="fw-semibold ms-2">
                                    No Limit
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">
                                    Minimum Trading Days
                                  </span>
                                  :<span className="fw-semibold ms-2">0</span>
                                </li>
                                <li>
                                  <span className="me-2">Profit Split</span>:
                                  <span className="fw-semibold ms-2">
                                    80% 95% with addon
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Trading Leverage</span>
                                  :
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    1:100
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">News Trading</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2">Weekend Holding</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    Allowed
                                  </span>
                                </li>
                                <li>
                                  <span className="me-2"> Refundable Fee</span>:
                                  <span className="fw-semibold ms-2">
                                    {" "}
                                    $949
                                  </span>
                                </li>
                                <li>
                                  <button className="primary-btn mt-3">
                                    Select Plan
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= Table End ============-->
  <!--============= Slider Section Start ============--> */}
      <section className="section-padding section-primary-bg">
        <div className="container">
          <div className="slider-bg">
            <div className="row">
              <div className="col-lg-8">
                <div className="slider-1">
                  <div className="tier-grp">
                    <OwlCarousel
                      id="landing_carousel"
                      items={1}
                      margin={8}
                      autoplay={true}
                      autoplayTimeout={2000}
                      autoplaySpeed={500}
                      loop
                    >
                      <div className="item">
                        <div className="tier-btn">
                          <p className="mb-0">Tier 1</p>
                        </div>
                        <h2 className="fw-bold mt-2">
                          Stay updated with our in depth trading rules
                        </h2>
                      </div>
                      <div className="item">
                        <div className="tier-btn">
                          <p className="mb-0">Tier 2</p>
                        </div>
                        <h2 className="fw-bold mt-2">
                          Stay updated with our in depth trading rules
                        </h2>
                      </div>
                      <div className="item">
                        <div className="tier-btn">
                          <p className="mb-0">Tier 3</p>
                        </div>
                        <h2 className="fw-bold mt-2">
                          Stay updated with our in depth trading rules
                        </h2>
                      </div>
                      <div className="item">
                        <div className="tier-btn">
                          <p className="mb-0">Tier 4</p>
                        </div>
                        <h2 className="fw-bold mt-2">
                          Stay updated with our in depth trading rules
                        </h2>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
                <div className="slider-2 mt-md-0 mt-5">
                  <OwlCarousel
                    loop
                    nav
                    dots={false}
                    id="landing_carousel_2"
                    className="owl-carousel owl-theme owl-drag "
                    responsive={{
                      0: {
                        items: 1,
                      },
                      600: {
                        items: 2,
                      },
                      1000: {
                        items: 3,
                      },
                    }}
                  >
                    <div className="item">
                      <div className="process-bg">
                        <h4 className="text-white pt-4 mt-md-0 mt-5">Tier 1</h4>
                      </div>
                    </div>
                    <div className="item">
                      <div className="process-bg">
                        <h4 className="text-white pt-4 mt-md-0 mt-5">Tier 2</h4>
                      </div>
                    </div>
                    <div className="item">
                      <div className="process-bg">
                        <h4 className="text-white pt-4 mt-md-0 mt-5">Tier 3</h4>
                      </div>
                    </div>
                    <div className="item">
                      <div className="process-bg">
                        <h4 className="text-white pt-4 mt-md-0 mt-5">Tier 4</h4>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div className="col-lg-4 profit-grp">
                <p className="profit-head mb-0 text-white wow animate__animated animate__flash  animate__slow animate__infinite">
                  6 OR 8%
                </p>
                <p>profit</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= Slider Section End ============-->
  <!--============= Four Column Start ============--> */}
      <section className="section-padding section-primary-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow animate__animated animate__zoomIn  animate__slower">
              <div className="d-flex">
                <img src={img20} alt="icon" width="64px" height="64px" />
                <div className="ms-3">
                  <h4 className="text-white">Expert service</h4>
                  <p className="mt-3">
                    Experience our unparalleled expertise in proprietary trading
                    From market analysis to personalized support, we're here to
                    help you navigate the financial markets with confidence
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow mt-lg-0 mt-4 animate__animated animate__zoomIn  animate__slower">
              <div className="d-flex">
                <img src={img21} alt="icon" width="64px" height="64px" />
                <div className="ms-3">
                  <h4 className="text-white">Secure Trading</h4>
                  <p className="mt-3">
                    Engage in secure educational trading. Knowing your interests
                    is our priority. Our commitment to transparency and
                    integrity ensures a trustworthy simulated trading
                    environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5 wow animate__animated animate__zoomIn  animate__slower">
              <div className="d-flex">
                <img src={img22} alt="icon" width="64px" height="64px" />
                <div className="ms-3">
                  <h4 className="text-white">Financial strength</h4>
                  <p className="mt-3">
                    Rely on our robust financial strength, providing stability
                    and confidence in your trading endeavors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5  wow animate__animated animate__zoomIn    animate__slower">
              <div className="d-flex">
                <img src={img23} alt="icon" width="64px" height="64px" />
                <div className="ms-3">
                  <h4 className="text-white">Integrated support</h4>
                  <p className="mt-3">
                    Access comprehensive integrated support every step of the
                    way, empowering your trading journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= Four Column End ============-->
  <!--============= Steps Start ============--> */}

      <section className="section-padding section-primary-bg">
        <div className="container">
          <div className="steps-bg">
            <div className="steps-div position-relative">
              <h4>Simple steps to start trade.</h4>
              <div className="row mt-5 text-center">
                <div className="col-lg-4">
                  <div className="first">
                    <p className=" wow animate__animated animate__zoomIn  animate__slower ">
                      1
                    </p>
                    <p className="mt-3">Select your plan</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="first">
                    <p className=" wow animate__animated animate__zoomIn  animate__slower ">
                      2
                    </p>
                    <p className="mt-3"> Pass the challenge</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="first">
                    <p className=" wow animate__animated animate__zoomIn  animate__slower ">
                      3
                    </p>
                    <p className="mt-3">Get Funded</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= Steps End ============-->
  <!--============= App Start ============--> */}

      <section className="section-padding section-primary-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src={img24} alt="laptop" className="img-fluid" />
            </div>
            <div className="col-lg-6">
              <p className="theme-primary-text">Match Trader</p>
              <h3 className="text-white">
                Discover endless opportunities with Global FX Funders virtual
                assets, including Forex options, precious metals like gold, and
                a variety of equities
              </h3>
              <p>Find your interest, start trading</p>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= Apps End ============-->
  <!--============= Blog Start ============--> */}

      {/* <section className='section-padding section-primary-bg'>
        <div className='container'>
          <h2 className='text-white'>stay ahead of the curved</h2>
          <div className='row mt-5'>
            <div className='col-lg-4'>
              <div className='d-flex'>
                <div className='in-liquid-category'>
                  <p className='uk-text-small uk-text-uppercase'>
                    <span className='bg-white p-1'>News</span>
                  </p>
                </div>
                <div>
                  <h4 className='text-white'>
                    Wall Street cautious on 'frothy' stocks, warn bitcoin bubble
                  </h4>
                  <span className='text-white mt-2'>
                    By Reuters • Jan 8, 2021
                  </span>
                  <p className='mt-3'>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit ...
                  </p>
                  <button className='mt-4 readmore trans-bg'  onClick={Blog}>
                    Read more
                    <i className='fas fa-long-arrow-alt-right uk-margin-small-left'></i>
                  </button>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='d-flex'>
                <div className='in-liquid-category'>
                  <p className='uk-text-small uk-text-uppercase'>
                    <span className='bg-white p-1'>Analysis</span>
                  </p>
                </div>
                <div>
                  <h4 className='text-white'>
                    Wall Street cautious on 'frothy' stocks, warn bitcoin bubble
                  </h4>
                  <span className='text-white mt-2'>
                    By Reuters • Jan 8, 2021
                  </span>
                  <p className='mt-3'>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit ...
                  </p>
                  <button className='mt-4 readmore trans-bg'>
                    Read more
                    <i className='fas fa-long-arrow-alt-right uk-margin-small-left'></i>
                  </button>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='d-flex'>
                <div className='in-liquid-category'>
                  <p className='uk-text-small uk-text-uppercase'>
                    <span className='bg-white p-1'>Education</span>
                  </p>
                </div>
                <div>
                  <h4 className='text-white'>
                    Wall Street cautious on 'frothy' stocks, warn bitcoin bubble
                  </h4>
                  <span className='text-white mt-2'>
                    By Reuters • Jan 8, 2021
                  </span>
                  <p className='mt-3'>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit ...
                  </p>
                  <button className='mt-4 readmore trans-bg'>
                    Read more
                    <i className='fas fa-long-arrow-alt-right uk-margin-small-left'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <!--============= Blog End ============-->
  <!--============= FAQ End ============--> */}
      <section className="section-padding section-primary-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="tilte-header row justify-content-between">
                <div className="col-lg-12">
                  <h2>
                    Our Most More Asked <span>Questions</span>
                  </h2>
                </div>
              </div>
              <p>
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
              </p>
            </div>
            <div className="col-lg-7 faq-grp">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Can I hold trades during news and over the weekend?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                  >
                    <div className="accordion-body">
                      <strong>
                        During our evaluation we have no restrictions regarding
                        news trading and holding…
                      </strong>{" "}
                      It is shown by default, until the collapse plugin adds the
                      appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Expert Advisor (EA) usage on Evaluation and Master Account
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body">
                      <strong>This is the second item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      How do I get funded by Funding Pips?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body">
                      <strong>This is the third item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseFour"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseFour"
                    >
                      How do I get funded by Funding Pips?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseFour"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body">
                      <strong>This is the third item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= FFAQ End ============-->
  <!--============= Award Section Start ============--> */}
      <section className="section-padding section-primary-bg d-none">
        <div className="container">
          <div className="d-block d-md-flex justify-content-between align-content-center">
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                fill="#027a4f"
                className="bi bi-award-fill"
                viewBox="0 0 16 16"
              >
                <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z" />
                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
              </svg>
              <h4 className="text-white mt-3 mb-2">Best CFD Broker</h4>
              <p>TradeON Summit 2020</p>
            </div>
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                fill="#027a4f"
                className="bi bi-award-fill"
                viewBox="0 0 16 16"
              >
                <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z" />
                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
              </svg>
              <h4 className="text-white mt-3 mb-2">Best CFD Broker</h4>
              <p>TradeON Summit 2020</p>
            </div>
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                fill="#027a4f"
                className="bi bi-award-fill"
                viewBox="0 0 16 16"
              >
                <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z" />
                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
              </svg>
              <h4 className="text-white mt-3 mb-2">Best CFD Broker</h4>
              <p>TradeON Summit 2020</p>
            </div>
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                fill="#027a4f"
                className="bi bi-award-fill"
                viewBox="0 0 16 16"
              >
                <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z" />
                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
              </svg>
              <h4 className="text-white mt-3 mb-2">Best CFD Broker</h4>
              <p>TradeON Summit 2020</p>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= Award Section End ============-->
  <!--============= Payment MEthod Start ============--> */}
      <section className="section-padding section-primary-bg">
        <div className="container">
          <div className="tilte-header">
            <div className="col-lg-12 text-center">
              <h2>
                Payment <span>Method</span>
              </h2>
            </div>
          </div>
          <div className="mt-4 d-block d-md-flex justify-content-between text-center align-content-center">
            <div>
              <img src={img25} alt="" width="100px" />
            </div>

            <div className="mt-4 mt-sm-0">
              <img src={img27} alt="" width="100px" />
            </div>
            <div className="mt-4 mt-sm-0">
              <img src={img28} alt="" width="100px" />
            </div>
            <div className="mt-4 mt-sm-0">
              <img src={img29} alt="" width="100px" />
            </div>
            <div className="mt-4 mt-sm-0">
              <img src={img30} alt="" width="100px" />
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-8 text-center">
              <div className="bg-secondary d-block d-md-flex justify-content-between align-items-center p-2 rounded-3 d-table">
                <p className="mb-0">
                  Don't see a payment methods that works for you? We have other
                  options.
                </p>
                <div>
                  <button className="primary-btn mt-4 mt-sm-0">
                    More options
                    <i className="fas fa-angle-right uk-margin-small-left"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="d-flex justify-content-center mt-5">
              <div className="d-flex align-items-center">
                <div className="border rounded-2 p-2">
                  <i className="fas fa-history text-white"></i>
                </div>
                <div>
                  <h6 className="text-white ms-2 mb-0">
                    25 years of Excellence
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center ms-4">
                <div className="border rounded-2 p-2">
                  <i className="fas fa-trophy text-white"></i>
                </div>
                <div>
                  <h6 className="text-white ms-2 mb-0">15+ Global Awards</h6>
                </div>
              </div>
              <div className="d-flex align-items-center ms-4">
                <div className="border rounded-2 p-2">
                  <i className="fas fa-phone-alt text-white"></i>
                </div>
                <div>
                  <h6 className="text-white ms-2 mb-0">
                    24/5 Customer Support
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--============= Payment Method End ============-->
  <!--============= Footer Start ============--> */}

      {/* <!--============= Footer End ============--> */}
    </Layout>
  );
}

export default App;
