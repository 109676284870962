import Layout from './Layout'
import blog1 from '../assets/images/blog/blog_1.png'
function Blog () {
  return (
    <Layout>
      <div className='inner-page-bg sction-padding'>
        <div className='container section-padding'>
          {/* <h2 className='text-white fw-bold'>Blog</h2> */}
            <div className="row">
              <div className="col-lg-8">
                <div className="card-div tilte-header">
                <h2>Wall Street cautious on  <span>frothy</span> stocks, warn bitcoin bubble</h2>
                  <div>
                    <img
                      src={blog1}
                      alt=''
                      className='my-3 img-fluid wow animate__animated  animate__delay-2s  animate__slow '
                    />
                  </div>
                  <span className="text-white mt-3">By Reuters • Jan 8, 2021</span>
                  <p className='mt-2'>
                  Forex trading is one of the most popular forms of trading in the financial markets. It involves trading in different currencies from around the world in order to make a profit. Forex traders take advantage of the differences in currency exchange rates to make a profit by buying and selling different currencies. Forex traders must have an understanding of the global economic and geopolitical environment in order to make informed trading decisions. They must also be aware of the different technical indicators and strategies used to analyse the markets. Forex trading requires discipline, knowledge, and experience in order to be successful. Traders must also be aware of the risks associated with the markets and use risk management techniques to protect their capital.
                  </p>
                  <p className='mt-2'>
                  Forex trading is one of the most popular forms of trading in the financial markets. It involves trading in different currencies from around the world in order to make a profit. Forex traders take advantage of the differences in currency exchange rates to make a profit by buying and selling different currencies. Forex traders must have an understanding of the global economic and geopolitical environment in order to make informed trading decisions. They must also be aware of the different technical indicators and strategies used to analyse the markets. Forex trading requires discipline, knowledge, and experience in order to be successful. Traders must also be aware of the risks associated with the markets and use risk management techniques to protect their capital.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 recent-news-grp ">
                <div className="card-div tilte-header">
                  <h4>Recent News</h4>
                  <div className='d-flex align-items-center recnt-bor'>
                    <div className='w-25'>
                        <img
                          src={blog1}
                          alt=''
                          className='img-fluid rounded-corner wow animate__animated  animate__delay-2s  animate__slow '
                        />
                    </div>
                    <div className='ms-2'>
                      <h5 className='text-white'>Wall Street cautious on....</h5>
                      <span className="text-white mt-3">By Reuters • Jan 8, 2021</span>
                    </div>
                  </div>
                  <div className='d-flex align-items-center recnt-bor'>
                    <div className='w-25'>
                        <img
                          src={blog1}
                          alt=''
                          className='img-fluid rounded-corner wow animate__animated  animate__delay-2s  animate__slow '
                        />
                    </div>
                    <div className='ms-2'>
                      <h5 className='text-white'>Wall Street cautious on....</h5>
                      <span className="text-white mt-3">By Reuters • Jan 8, 2021</span>
                    </div>
                  </div>
                  <div className='d-flex align-items-center recnt-bor'>
                    <div className='w-25'>
                        <img
                          src={blog1}
                          alt=''
                          className='img-fluid rounded-corner wow animate__animated  animate__delay-2s  animate__slow '
                        />
                    </div>
                    <div className='ms-2'>
                      <h5 className='text-white'>Wall Street cautious on....</h5>
                      <span className="text-white mt-3">By Reuters • Jan 8, 2021</span>
                    </div>
                  </div>
                  <div className='d-flex align-items-center recnt-bor'>
                    <div className='w-25'>
                        <img
                          src={blog1}
                          alt=''
                          className='img-fluid rounded-corner wow animate__animated  animate__delay-2s  animate__slow '
                        />
                    </div>
                    <div className='ms-2'>
                      <h5 className='text-white'>Wall Street cautious on....</h5>
                      <span className="text-white mt-3">By Reuters • Jan 8, 2021</span>
                    </div>
                  </div>
                  <div className='d-flex align-items-center recnt-bor'>
                    <div className='w-25'>
                        <img
                          src={blog1}
                          alt=''
                          className='img-fluid rounded-corner wow animate__animated  animate__delay-2s  animate__slow '
                        />
                    </div>
                    <div className='ms-2'>
                      <h5 className='text-white'>Wall Street cautious on....</h5>
                      <span className="text-white mt-3">By Reuters • Jan 8, 2021</span>
                    </div>
                  </div>
                </div>
                <div className='card-div mt-4'>
                    <h4>Categories</h4>
                    <ul className='mt-3'>
                      <li><h5 className='text-white'>Student</h5></li>
                      <li><h5 className='text-white mt-2'>Experience</h5></li>
                      <li><h5 className='text-white mt-2'>Advanced</h5></li>
                      <li><h5 className='text-white mt-2'>Experience</h5></li>
                      <li><h5 className='text-white mt-2'>Advanced</h5></li>
                    </ul>
                </div>
            </div>
         
            </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blog
