import React from 'react'
import { useLocation } from 'react-router-dom'
import Layout from './Layout'

function SingleFaq () {
  const location = useLocation()
  return (
    <Layout>
      <div className='inner-page-bg sction-padding'>
        <div className='container section-padding'>
          <h2 className='text-white fw-bold'>
            {location.state === null || location.state === undefined
              ? '1.Can I hold trades during news and over the weekend ?'
              : location.state.question}
          </h2>
          <p className='text-secondary mt-2 '>
            {location.state === null || location.state === undefined
              ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minim veniam, quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat. Duis aute irure dolor inreprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              : location.state.content}
          </p>
          <p className='text-secondary mt-2 '>
            {location.state === null || location.state === undefined
              ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimad minim veniam, quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat. Duis aute irure dolor inreprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              : location.state.content}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default SingleFaq
