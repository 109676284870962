// hooks
import { useHandleForm } from '../hook/useHandleForm'

// components
import Info from './Info';
import Challenge from './Challenge';

// framer motion
import { motion as m } from 'framer-motion'
import Layout from './Layout';
import SubHeader from './SubHeader';

function NewFrame() {
  
const {step, next, prev, goto} = useHandleForm(['0', '1', '2', '3', '4'])
  
  return (

    <Layout>
      <div className='container-fluid inner-page-bg-1'>
        <div className='row'>
            <SubHeader />
            <div className='col-lg-9 dash-top mt-23'>
              <div className=' multi-form mb-5'>
              <m.main
                initial={{y:'-100%'}}
                animate={{y:'0%'}}
              >
                <Info
                  step={step}
                />
                <Challenge
                  step={step}
                  next={next} 
                  prev={prev}
                  goto={goto}
                />
              </m.main>
              </div>
            </div>
        </div>
      </div>
    </Layout>
   
  );
}

export default NewFrame;
