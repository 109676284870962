import React, { useState, useEffect } from 'react'
import SubHeader from './SubHeader'
import { Link, useNavigate } from 'react-router-dom'
import Layout from './Layout'
import '../assets/ListAndAdd.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NavLink } from 'react-router-dom'
import {useApi} from '../hook/useApi'

const Payout = () => {
  
  const [requestHit] = useApi();
  
  const navigate = useNavigate(); 

  const [kycDetails, setKycDetails] = useState({
    first_name : '',
    last_name : '',
    gender: '',
    dob: '',
    nationality: '',
    resitance_status: '',
    pan_number: ''
  })

  useEffect(()=>{
    requestHit('get', 'user/kycget').then(response => {
      if (response.status) {
        setKycDetails(response.data);
        localStorage.setItem('KYCDETAIL', JSON.stringify(response.data));
      } else {
        setKycDetails({
          first_name : '',
          last_name : '',
          gender: '',
          dob: '',
          nationality: '',
          resitance_status: '',
          pan_number: ''
        })
      }
    })
  },[]);

  const handleValuechange = (event)=> {
    setKycDetails((previous)=>{
      return { ...previous, [event.target.name]: event.target.value }
    })
  }

  const handleSubmit = ()=>{
    localStorage.setItem('KYCDETAIL', JSON.stringify(kycDetails));
    navigate('/kycStepTwo');
  }
  

  return (
    <>
      {localStorage.getItem('token') !== null ||
      localStorage.getItem('token') !== undefined ? (
        <Layout>
          <div className='container-fluid  inner-page-bg'>
            <div className='row'>
            <SubHeader />
              <div className='col-lg-9 mt-4'>
                <div className='card-div p-4 kyc-form-group'>
                    <h3 className='text-white text-center'>Profile </h3>
                    <h5 className='text-white mt-3'>Identity Details </h5>
                    <div className='row mt-4'>
                      <div className='col-lg-6'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                            First Name <span>*</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='First Name'
                            aria-label='First Name'
                            aria-describedby='basic-addon2'
                            name='first_name'
                            value={kycDetails.first_name}
                            onChange={(e)=> { handleValuechange(e) } }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                            Last Name <span>*</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Last Name'
                            aria-label='Last Name'
                            aria-describedby='basic-addon2'
                            name='last_name'
                            value={kycDetails.last_name}
                            onChange={(e)=> { handleValuechange(e) } }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 form-div mt-4'>
                        <label htmlFor='' className=''>
                            Gender <span>*</span>
                          </label>
                        <div className='mb-3 form-div d-flex'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" id="flexRadioDefault1" name="gender" onChange={(e)=> { handleValuechange(e) } } value="male" checked={ (kycDetails.gender === 'male')? true : false } />
                            <label className="form-check-label" htmlFor="flexRadioDefault1" >
                             Male
                            </label>
                          </div>
                          <div className="form-check ms-4">
                            <input className="form-check-input" type="radio" id="flexRadioDefault2" name="gender" onChange={(e)=> { handleValuechange(e) } } value="female"  checked={ (kycDetails.gender === 'female')? true : false } />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                             Female
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                            Date of Birth <span>*</span>
                          </label>
                          <input
                            type='Date'
                            className='form-control'
                            placeholder='Date'
                            aria-label='Last Name'
                            aria-describedby='basic-addon2'
                            name='dob'                            
                            value={kycDetails.dob}
                            onChange={(e)=> { handleValuechange(e) } }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                          Nationality <span>*</span>
                          </label>
                          <select className="form-select" aria-label="Default select example" value={kycDetails.nationality} name='nationality'  onChange={(e)=> { handleValuechange(e) } }>
                            <option value=''>Select the Nationlaity</option>
                            <option value="India">India</option>
                            <option value="USA">America</option>
                            <option value="Australia">Australia</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-lg-6 form-div mt-4'>
                        <label htmlFor='' className=''>
                        Status <span>*</span>
                          </label>
                        <div className='mb-3 form-div d-flex'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" id="flexRadioDefault1" name='resitance_status'  onChange={(e)=> { handleValuechange(e) } } value="resident" checked={kycDetails.resitance_status === 'Resident' ? true : false } />
                            <label className="form-check-label" htmlFor="flexRadioDefault1" >
                            Resident Individual
                            </label>
                          </div>
                          <div className="form-check ms-4">
                            <input className="form-check-input" type="radio" id="flexRadioDefault2" name='resitance_status'  onChange={(e)=> { handleValuechange(e) } } value="non_resident" checked={kycDetails.resitance_status === 'non_resident' ? true : false }  />
                            <label className="form-check-label" htmlFor="flexRadioDefault2" >
                            Non Resident
                            </label>
                          </div>
                          <div className="form-check ms-4">
                            <input className="form-check-input" type="radio" id="flexRadioDefault3" name='resitance_status'  onChange={(e)=> { handleValuechange(e) } } value="foreign" checked={kycDetails.resitance_status === 'foreign' ? true : false }  />
                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                            Foreign National
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                            Pan Number <span>*</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Pan Number'
                            aria-label='Last Name'
                            aria-describedby='basic-addon2' name='pan_number'  
                            value={kycDetails.pan_number}
                            onChange={(e)=> { handleValuechange(e) } } 
                          />
                        </div>
                      </div>
                    </div>
                    <div className='mt-4 text-end'>
                        {/* <Link to='/kycsteptwo'> */}
                          <button className="primary-btn btn-sm" onClick={(e)=> { handleSubmit() }}>
                            Next
                          </button>
                        {/* </Link> */}
                      </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      ) : (
        <>
          <NavLink to='/login' />
        </>
      )}
    </>
  )
}

export default Payout
