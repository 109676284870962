import React, { useState, useEffect } from 'react'
import SubHeader from './SubHeader'
import Layout from './Layout'
import { Link } from 'react-router-dom'
import '../assets/ListAndAdd.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NavLink } from 'react-router-dom'
import {useApi} from '../hook/useApi'
import front from '../assets/images/frontside.png'
import back from '../assets/images/backside.png'

const Payout = () => {

  
  const [requestHit] = useApi();

  const [kycDetail, setKycDetail] = useState({});

  const [frontImage, setFrontImage] = useState(null);
  const [frontUrl, setFrontURL] = useState(null);

  const [backImage, setBackImage] = useState(null);
  const [backUrl, setBackURL] = useState(null);

  useEffect(()=>{
    const kycDetailTemp = JSON.parse(localStorage.getItem("KYCDETAIL"))
    setKycDetail(kycDetailTemp);
  }, [])

  const handleFrontChange = (event) => {
    const file = event.target.files[0];
    setFrontImage(file);
    
    // Read the selected file and set the preview URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setFrontURL(reader.result);
    };
    reader.readAsDataURL(file);
  };

  
  const handleBackChange = (event) => {
    const file = event.target.files[0];
    setBackImage(file);
    
    // Read the selected file and set the preview URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setBackURL(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (frontImage && backImage ) {
      // Prepare form data
      // const formData = new FormData();
      // for (const key in kycDetail) {
      //   if (kycDetail.hasOwnProperty(key)) {
      //     formData.append(key, kycDetail[key]);
      //   }
      // }

      
      // formData.append('front_image', frontImage);
      // formData.append('back_image', backImage);

      requestHit('post', 'user/kycSubmit', kycDetail ).then(response => {
        if(response.status){
          toast.success("KYC submitted. Please wait for admin approval.");
        }else{          
          toast.success("Sorry please try again sfter sometime.");
        }
      })
    }
  };

  return (
    <>
      {localStorage.getItem('token') !== null ||
      localStorage.getItem('token') !== undefined ? (
        <Layout>
          <div className='container-fluid  inner-page-bg'>
            <div className='row'>
            <SubHeader />
              <div className='col-lg-9 mt-4'>
                <div className='card-div p-4 kyc-form-group'>
                  <form onSubmit={handleSubmit}>
                    <h3 className='text-white text-center'>Profile </h3>
                    <h5 className='text-white mt-3'>File Upload </h5>
                    <div className='row mt-4'>
                      <div className='col-lg-6'>
                          <h6 className='text-white'>Front *</h6>
                          <div>
                          <div className="file-input">
                            <input
                              type="file"
                              name="front_image"
                              id="front_image"
                              className="front_image__input"
                              onChange={(e)=> { handleFrontChange(e) }} 
                            />
                          </div>
                          </div>  
                          <label htmlFor="front_image">
                          <img
                                src={ frontUrl ? frontUrl : front }
                                alt='ellipse'
                                className='img-fluid mt-3'
                                width='400'
                              />
                          </label>
                      </div>
                      <div className='col-lg-6 mt-5'>
                          <h6 className='text-white'>Back *</h6>
                          <div>
                          <div className="file-input">
                            <input
                              type="file"
                              name="back_image"
                              id="back_image"
                              className="back_image__input"
                              onChange={(e)=> { handleBackChange(e) }} 
                            />
                          </div>
                          </div>  
                          <label htmlFor="back_image">
                          <img
                                src={ backUrl ? backUrl : back }
                                alt='ellipse'
                                className='img-fluid mt-3'
                                width='400'
                              />
                          </label>
                      </div>
                    </div>
                    <div className='mt-4 text-end'>
                        <Link to='/kycsteptwo'>
                          <button className="primary-btn btn-sm">
                            Back
                          </button>
                        </Link>

                        {
                          kycDetail?._id ? (
                            <div>
                              {/* Submitted */}
                            </div>
                          ) : (
                            <button className="primary-btn btn-sm ms-3" >
                              Submit
                            </button>
                          )
                        }
                       
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      ) : (
        <>
          <NavLink to='/login' />
        </>
      )}
    </>
  )
}

export default Payout
