import Layout from "./Layout";
import SubHeader from "./SubHeader";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import PieChart from "./PieChart";
import { useApi } from "../hook/useApi";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import accountsize from "../assets/images/dashboard/account-size.png";
import balance from "../assets/images/dashboard/balance.png";
import profit from "../assets/images/dashboard/profit.png";
import loss from "../assets/images/dashboard/loss.png";

function Dashboard() {
  const [requestHit] = useApi();
  const pageParams = useParams();
  const navigate = useNavigate();

  const [accountId, setAccountId] = useState(null);

  const [dashboardDetails, setDashboardDetails] = useState(null);
  const [accountDetails, setAccountDetails] = useState({});
  const [accountList, setAccountList] = useState([]);
  const [groupedAccounts, setGroupedAccounts] = useState([]);
  const [passwordPopup, openPasswordPopup] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const [password, passwordChange] = useState("");
  const [dailyLossChart, setDailyLossChart] = useState(null);
  const [maxLossChart, setMaxLossChart] = useState(null);
  const [profitChart, setProfitChart] = useState(null);

  useEffect(() => {
    tradingInstructor(dashboardDetails);
  }, [dashboardDetails]);
  useEffect(() => {
    if (pageParams.id) {
      setAccountId(pageParams.id);
    } else {
      setAccountId(null);
    }
  }, [pageParams]);

  useEffect(() => {
    if (accountId) {
      // setInterval(() => {
      requestHit("post", "user/dashboard-details", { _id: accountId }).then(
        (response) => {
          if (response.status) {
            console.log(response.data);
            setDashboardDetails(response.data);
            tradingInstructor(response.data);
          } else {
            setDashboardDetails({});
          }
        }
      );
      // }, 5000);
    } else {
      console.log("account empty");
    }
  }, [accountId]);

  useEffect(() => {
    requestHit("get", "user/get-challenge").then((response) => {
      if (response.status) {
        if (response.status) {
          const groupedList = response.data.reduce((grouped, obj) => {
            const { account_type, phase, ...rest } = obj;
            if (!grouped[phase]) {
              grouped[phase] = [];
            }
            grouped[phase].push(rest);
            return grouped;
          }, {});

          setGroupedAccounts(groupedList);

          setAccountList(response.data);
          if (accountId) {
            const account = response.data.find(
              (item) => item._id === accountId
            );
            setAccountDetails(account);
            // tradingInstructor();
          }
        } else {
          setAccountList({});
        }
      } else {
        setDashboardDetails({});
      }
    });
  }, []);

  const AccountDetails = () => {
    const formattedDate = moment(dashboardDetails?.createdAt).format(
      "YYYY-MM-DD"
    );

    const tradingRules =
      dashboardDetails && dashboardDetails.tradingRules
        ? dashboardDetails.tradingRules
        : {};

    return (
      <div className="card-div mt-4">
        <h4>Account Details</h4>
        {dashboardDetails?.tradingRules?.accountSize ? (
          <ul className="list-unstyled mt-3 dashboard-accout-grp">
            <li className="d-flex justify-content-between text-white fw-semibold ">
              <span>Numbers</span>
              <span> #{accountId}</span>
            </li>
            <li className="d-flex justify-content-between text-white fw-semibold mt-3">
              <span>Platform</span>
              <span>{dashboardDetails.platform}</span>
            </li>
            <li className="d-flex justify-content-between text-white fw-semibold mt-3">
              <span>Account Size</span>
              <span>
                $
                {tradingRules.accountSize.defaultSize
                  ? tradingRules.accountSize.defaultSize
                  : tradingRules.accountSize.size}
              </span>
            </li>
            <li className="d-flex justify-content-between text-white fw-semibold mt-3">
              <span>Account Type</span>
              <span>{dashboardDetails.tradingRules.type}</span>
            </li>
            <li className="d-flex justify-content-between text-white fw-semibold mt-3">
              <span>Phase</span>
              <span>{dashboardDetails.phase}</span>
            </li>
            <li className="d-flex justify-content-between text-white fw-semibold mt-3">
              <span>Status</span>
              <span className="text-white">{dashboardDetails.phaseStatus}</span>
            </li>
            {/* <li className='d-flex justify-content-between text-white fw-semibold mt-3'>
            <span>Total Payouts</span>
            <span>0.000 $</span>
          </li> */}
            <li className="d-flex justify-content-between text-white fw-semibold mt-3">
              <span>Start Trade Period</span>
              <span>{formattedDate}</span>
            </li>
            <li className="d-flex justify-content-between text-white fw-semibold mt-3">
              <span>Trading Days</span>
              <span>{dashboardDetails?.no_of_days}</span>
            </li>
            <li className="d-flex justify-content-between text-white fw-semibold mt-3">
              <span>Nunber of Open positions</span>
              <span>{dashboardDetails?.open_positions.length}</span>
            </li>
            <li className="d-flex justify-content-between text-white fw-semibold mt-3">
              <span>Nunber of Trades</span>
              <span>{dashboardDetails?.deals_history.length}</span>
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
    );
  };

  const tradingInstructor = (dashboardDetails = null) => {
    try {
      const rules = dashboardDetails?.trading_rules;

      if (!dashboardDetails?.trading_rules) {
        return false;
      }

      const dailyLossAmount =
        (dashboardDetails?.account_size / 100) * rules?.daily_loss_limit;
      const minProfAmount = dashboardDetails?.max_loss;
      const dailyLossPercent =
        dashboardDetails?.max_loss < 0
          ? ((minProfAmount / dailyLossAmount) * 100).toFixed(2)
          : 0;

      const orgSize = dashboardDetails.tradingRules.accountSize.defaultSize;
      let chartA = 100;
      const profitVal = dashboardDetails?.profit;
      // const profitVal = -0.5;
      if (profitVal < 0) {
        chartA = (Math.abs(profitVal) / orgSize) * 100;
        if (chartA >= rules?.daily_loss_limit) {
          chartA = 0;
        } else {
          chartA = rules?.daily_loss_limit - chartA;
        }
      }

      const tempDailyChart = {
        labels: ["Today Loss", "Remaining Loss Limit"],
        datasets: [
          {
            label: "# of progress",
            data: [dailyLossPercent, 100 - dailyLossPercent],
            // data: [rules?.daily_loss_limit, chartA],
            backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ],
      };

      setDailyLossChart(tempDailyChart);

      const maxLossAmount =
        (dashboardDetails?.account_size / 100) * rules?.max_loss_limit;
      const maxOverallAmount = dashboardDetails?.overall_lose;
      const maxLossPercent =
        dashboardDetails?.overall_lose < 0
          ? ((maxOverallAmount / maxLossAmount) * 100).toFixed(2)
          : 0;

      let chartB = 0;
      if (dashboardDetails?.profit > 0) {
        chartB = rules?.max_loss_limit;
      } else {
        chartB = (Math.abs(dashboardDetails?.profit) / orgSize) * 100;
      }

      const tempMaxChart = {
        labels: ["Max Loss", "Remaining Max Limit"],
        datasets: [
          {
            label: "# of progress",
            // data: [maxLossPercent, 100-maxLossPercent],
            data: [rules?.max_loss_limit, chartB],
            backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ],
      };

      setMaxLossChart(tempMaxChart);

      const profitTargetAmount =
        dashboardDetails?.profit > 0
          ? (dashboardDetails?.account_size / 100) * rules?.profit_target
          : (dashboardDetails?.account_size / 100) * rules?.profit_target +
            dashboardDetails?.profit;
      const profitAmount = dashboardDetails?.profit;
      const profitTargetPercent =
        dashboardDetails?.profit > 0
          ? ((profitAmount / profitTargetAmount) * 100).toFixed(2)
          : 0;

      const tempProfitChart = {
        labels: ["Target Reached", "Remaining Profit Target"],
        datasets: [
          {
            label: "# of progress",
            data: [profitTargetPercent, 100 - profitTargetPercent],
            backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ],
      };

      setProfitChart(tempProfitChart);
    } catch (err) {
      console.log(err);
    }
  };

  const DateTimeFormat = ({ time }) => {
    const formattedDate = moment(parseInt(time)).format(
      "YYYY-MM-DD hh:mm:ss a"
    );
    return <>{formattedDate}</>;
  };

  const DisplayValue = ({ value, positions, divide }) => {
    const valueTemp = value / divide;
    const formattedValue = Number(valueTemp).toFixed(positions);
    return <>{formattedValue}</>;
  };

  const getUserCredentials = (login) => {
    requestHit("post", "user/get-credentials", {
      login: login,
      password: password,
    }).then((response) => {
      setCredentials(response.data);
    });
  };

  const setActiveAccount = (loginId) => {
    const account = accountList.find((item) => item._id === loginId);
    setAccountDetails(account);
    //tradingInstructor();
    setAccountId(loginId);
    navigate(`/dashboard/${loginId}`);
  };

  return (
    <>
      {localStorage.getItem("token") === null ||
      localStorage.getItem("token") === undefined ? (
        <NavLink to="/login" />
      ) : (
        <Layout>
          <div>
            <div className="container-fluid inner-page-bg">
              <div className="row">
                <SubHeader />
                <div className="col-lg-9">
                  {accountId ? (
                    <div>
                      {/* New Dashboard */}
                      <div>
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb bg-transparent breadcrumb-chevron p-3 rounded-3">
                            <li className="breadcrumb-item">
                              <Link className="link-body-emphasis">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#027a4f"
                                  className="bi bi-house-door-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                                </svg>
                                <span className="visually-hidden text-secondary">
                                  New Challenge
                                </span>
                              </Link>
                            </li>
                            <li className="breadcrumb-item">
                              <Link
                                className="link-body-emphasis fw-semibold text-white text-decoration-none"
                                to="/dashboard"
                              >
                                Account
                              </Link>
                            </li>
                            <li
                              className="breadcrumb-item text-white active"
                              aria-current="page"
                            >
                              #{accountId}
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div>
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              src={require("../assets/images/dashboard/author.png")}
                              alt="author"
                              width="64px"
                              height="64px"
                            />
                          </div>
                          <div className="ms-3">
                            <h4 className="text-white">Account</h4>
                            <p>{accountDetails?.login}</p>
                          </div>
                          <div className="ms-3">
                            <button
                              className="primary-btn btn-sm"
                              onClick={() => {
                                openPasswordPopup(true);
                              }}
                            >
                              Credentials
                            </button>
                          </div>
                        </div>
                      </div>
                      {dashboardDetails?.tradingRules?.accountSize ? (
                        <div className="row mt-4">
                          <div className="col-lg-3">
                            <div className="card-div ">
                              <div className="d-flex align-items-center">
                                <img
                                  src={accountsize}
                                  alt="ellipse"
                                  className="img-fluid"
                                  width="48"
                                />
                                <div className="ms-3">
                                  <h4>
                                    ${" "}
                                    <DisplayValue
                                      divide={1}
                                      value={
                                        dashboardDetails.tradingRules
                                          .accountSize.defaultSize
                                      }
                                      positions={2}
                                    />
                                  </h4>
                                  <p className="mb-0">Account Size</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="card-div ">
                              <div className="d-flex align-items-center">
                                <img
                                  src={balance}
                                  alt="ellipse"
                                  className="img-fluid"
                                  width="48"
                                />
                                <div className="ms-3">
                                  <h4>
                                    ${" "}
                                    <DisplayValue
                                      divide={100}
                                      value={dashboardDetails?.balance}
                                      positions={2}
                                    />
                                  </h4>
                                  <p className="mb-0">Balance</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {dashboardDetails?.profit >= 0 && (
                            <div className="col-lg-3">
                              <div className="card-div ">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={profit}
                                    alt="ellipse"
                                    className="img-fluid"
                                    width="48"
                                  />
                                  <div className="ms-3">
                                    <h4>
                                      ${" "}
                                      <DisplayValue
                                        divide={1}
                                        value={dashboardDetails?.profit}
                                        positions={2}
                                      />
                                    </h4>
                                    <p className="mb-0">Profit</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {dashboardDetails?.profit < 0 && (
                            <div className="col-lg-3">
                              <div className="card-div ">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={loss}
                                    alt="ellipse"
                                    className="img-fluid"
                                    width="48"
                                  />
                                  <div className="ms-3">
                                    <h4>
                                      ${" "}
                                      <DisplayValue
                                        divide={1}
                                        value={dashboardDetails?.profit}
                                        positions={2}
                                      />
                                    </h4>
                                    <p className="mb-0">Loss</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="">
                            <AccountDetails />
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="card-div mt-4">
                                <h4>Trading Objectives</h4>
                                {dashboardDetails?.tradingRules ? (
                                  <ul className="list-unstyled mt-3 dashboard-accout-grp">
                                    <li className="d-flex justify-content-between text-white fw-semibold ">
                                      <span>Daily Loss Limit</span>
                                      <span>
                                        {
                                          dashboardDetails.tradingRules
                                            .accountSize.daily_loss_limit
                                        }
                                      </span>
                                    </li>
                                    <li className="d-flex justify-content-between text-white fw-semibold mt-3">
                                      <span>Maximum Loss Limit</span>
                                      <span>
                                        {
                                          dashboardDetails.tradingRules
                                            .accountSize.max_loss_limit
                                        }
                                      </span>
                                    </li>
                                    <li className="d-flex justify-content-between text-white fw-semibold mt-3">
                                      <span>Minimum Trading Days</span>
                                      <span>
                                        {
                                          dashboardDetails.tradingRules
                                            .accountSize.minimum_trade_days
                                        }
                                      </span>
                                    </li>
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 mt-4">
                              <div className="card-div h-100">
                                <div>
                                  {dailyLossChart ? (
                                    <PieChart dataObj={dailyLossChart} />
                                  ) : (
                                    <>no found</>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="card-div mt-4 h-100">
                                <div>
                                  {maxLossChart ? (
                                    <PieChart dataObj={maxLossChart} />
                                  ) : (
                                    <>no found</>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="card-div mt-4 h-100">
                                <div>
                                  {profitChart ? (
                                    <PieChart dataObj={profitChart} />
                                  ) : (
                                    <>no found</>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {!groupedAccounts[0] &&
                      !groupedAccounts[1] &&
                      !groupedAccounts[2] ? (
                        <div className="dash-top">
                          <div className="text-white">
                            No Account details Found Please Take a{" "}
                            <Link to="/newchallenge">New Challenge</Link>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {groupedAccounts[1] ? (
                        // dash-top mt-4 h-500
                        <div className="slider-2 mt-md-0 mt-5">
                          <div className="position-relative">
                            <h6 className="diamond position-relative">
                              Learner
                              <div className="circle-round-1"></div>
                              <div className="circle-round-2"></div>
                            </h6>
                          </div>
                          <OwlCarousel
                            loop={false}
                            nav={true}
                            dots={false}
                            margin={10}
                            id="landing_carousel_3"
                            className="owl-carousel owl-theme owl-drag "
                            responsive={{
                              0: {
                                items: 1,
                              },
                              600: {
                                items: 2,
                              },
                              1000: {
                                items: 3,
                              },
                            }}
                          >
                            {groupedAccounts[1]?.map((account, index) => (
                              <div
                                onClick={(e) => {
                                  setActiveAccount(account._id);
                                }}
                                className="mt-4 text-decoration-none"
                                key={account._id}
                              >
                                <div className="item">
                                  <div className="card-div  ">
                                    <div className="align-items-center account-details">
                                      <div className="d-flex align-items-center">
                                        <div className="d-grid">
                                          <h6 className="text-white mt-3 text-truncate text-break account-login-font">
                                            #{account.login}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="mt-3">
                                        <div className="d-flex justify-content-between">
                                          <div className="text-secondary">
                                            Status :
                                          </div>
                                          <div className="text-white"> {account.phaseStatus}</div>
                                          {/* {account.status == 0
                                            ? <div className="text-white">Inprogress</div>
                                            : account.status == 1
                                            ? <div className="text-white">Pending Approval</div>
                                            : account.status == 2
                                            ? <div className="text-white">Approved</div>
                                            : <div className="text-danger">Rejected</div>} */}
                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                          <div className="text-secondary">
                                            Account Size :
                                          </div>
                                          <div className="text-white">
                                            {account.tradingRules.accountSize.defaultSize}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                          <div className="text-secondary">
                                            No. Of Trades :
                                          </div>
                                          <div className="text-white">
                                            {account.no_of_trades}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                          <div className="text-secondary">
                                            Days Trade :
                                          </div>
                                          <div className="text-white">
                                            {account.no_of_days}
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                          <div className="text-secondary">
                                            Profit :
                                          </div>
                                          <div className="text-white">
                                            <DisplayValue
                                              divide={1}
                                              value={account.profit}
                                              positions={2}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="d-flex justify-content-between mt-2 account-details-content">
                                          <div>
                                            <div className='text-white'>
                                              <div className='text-secondary'>Status :</div>
                                              <div className="text-danger">Inprogress</div>
                                            </div>
                                            <div className='text-white'>
                                              <div className='text-secondary'>Account Size :</div> {account.account_size}
                                            </div>
                                          </div>
                                          <div className="account-details">
                                            <div className='text-secondary'>Result :</div>
                                            <div className="text-white">-5.6%</div>
                                            <div className='text-white'><div className='text-secondary'>Days Trade :</div> {account.no_of_days}</div>
                                          </div>
                                        </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </OwlCarousel>
                        </div>
                      ) : (
                        <></>
                      )}

                      {groupedAccounts[2] ? (
                        <div className="slider-2 mt-md-0 mt-5">
                          <div className="position-relative">
                            <h6 className="diamond position-relative">
                              Performer
                              <div className="circle-round-1"></div>
                              <div className="circle-round-2"></div>
                            </h6>
                          </div>
                          <OwlCarousel
                            loop={false}
                            nav={true}
                            dots={false}
                            margin={10}
                            id="landing_carousel_4"
                            className="owl-carousel owl-theme owl-drag "
                            responsive={{
                              0: {
                                items: 1,
                              },
                              600: {
                                items: 2,
                              },
                              1000: {
                                items: 3,
                              },
                            }}
                          >
                            {groupedAccounts[2]?.map((account, index) => (
                              <div
                                onClick={(e) => {
                                  setActiveAccount(account._id);
                                }}
                                className="mt-4 text-decoration-none"
                                key={account._id}
                              >
                                <div className="item">
                                  <div className="card-div h-100">
                                    <div className="align-items-center account-details">
                                      <div className="d-flex align-items-center">
                                        <div className="d-grid">
                                          <h6 className="text-white mt-3 text-truncate text-break">
                                            #{account.login}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between mt-2 account-details-content">
                                        <div>
                                          <div className="text-white">
                                            <div className="text-secondary">
                                              Status :
                                            </div>
                                            <div className="text-danger">
                                              Inprogress
                                            </div>
                                          </div>
                                          <div className="text-white">
                                            <div className="text-secondary">
                                              Account Size :
                                            </div>{" "}
                                            {account.account_size}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="text-secondary">
                                            Result :
                                          </div>
                                          <div className="text-white">
                                            -5.6%
                                          </div>
                                          {/* <div className='text-white'><div className='text-secondary'>Number of Trade :</div> {account.no_of_trades}</div> */}
                                          <div className="text-white">
                                            <div className="text-secondary">
                                              Days Trade :
                                            </div>{" "}
                                            {account.no_of_days}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </OwlCarousel>
                        </div>
                      ) : (
                        <></>
                      )}

                      {groupedAccounts[3] ? (
                        <div className="slider-2 mt-md-0 mt-5">
                          <div className="position-relative">
                            <h6 className="diamond position-relative">
                              Expert
                              <div className="circle-round-1"></div>
                              <div className="circle-round-2"></div>
                            </h6>
                          </div>
                          <OwlCarousel
                            loop={false}
                            nav={true}
                            dots={false}
                            margin={10}
                            id="landing_carousel_5"
                            className="owl-carousel owl-theme owl-drag "
                            responsive={{
                              0: {
                                items: 1,
                              },
                              600: {
                                items: 2,
                              },
                              1000: {
                                items: 3,
                              },
                            }}
                          >
                            {groupedAccounts[3]?.map((account, index) => (
                              <div
                                onClick={(e) => {
                                  setActiveAccount(account._id);
                                }}
                                className="mt-4 text-decoration-none"
                                key={account._id}
                              >
                                <div className="item">
                                  <div className="card-div h-100">
                                    <div className="align-items-center account-details">
                                      <div className="d-flex align-items-center">
                                        <div className="d-grid">
                                          <h6 className="text-white mt-3 text-truncate text-break">
                                            #{account.login}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between mt-2 account-details-content">
                                        <div>
                                          <div className="text-white">
                                            <div className="text-secondary">
                                              Status :
                                            </div>
                                            <div className="text-danger">
                                              Inprogress
                                            </div>
                                          </div>
                                          <div className="text-white">
                                            <div className="text-secondary">
                                              Account Size :
                                            </div>{" "}
                                            {account.account_size}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="text-secondary">
                                            Result :
                                          </div>
                                          <div className="text-white">
                                            -5.6%
                                          </div>
                                          {/* <div className='text-white'><div className='text-secondary'>Number of Trade :</div> {account.no_of_trades}</div> */}
                                          <div className="text-white">
                                            <div className="text-secondary">
                                              Days Trade :
                                            </div>{" "}
                                            {account.no_of_days}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </OwlCarousel>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {passwordPopup && (
            <div className="popup-overlay ">
              <div className="col-4 col-lg-4 popup card-div">
                <div className="row">
                  <h4>Credentials</h4>
                  {credentials ? (
                    <>
                      <div className="col-12 my-2 text-white word-wrap">
                        <label>Login : </label>
                        <label className="mx-2"> {credentials.login}</label>
                      </div>
                      <div className="col-12 my-2 text-white  word-wrap">
                        <label>Password : </label>
                        <label className="mx-2"> {credentials.password}</label>
                      </div>
                    </>
                  ) : (
                    <div className="col-lg-12 col-md-12 my-2 form-div">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className="w-100 h-100"
                        placeholder="Enter Password"
                        onChange={(e) => passwordChange(e.target.value)}
                      />
                    </div>
                  )}
                </div>
                {credentials ? (
                  <div className="button-container">
                    <button
                      onClick={(e) => {
                        setCredentials(null);
                        openPasswordPopup(false);
                      }}
                      className="cancel-button"
                    >
                      Close
                    </button>

                    <Link
                      to={credentials.server}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="add-button"
                    >
                      Match Trader
                    </Link>
                  </div>
                ) : (
                  <div className="button-container">
                    <button
                      onClick={() => getUserCredentials(accountDetails?.login)}
                      className="add-button"
                    >
                      {" "}
                      Request{" "}
                    </button>
                    <button
                      onClick={(e) => {
                        setCredentials(null);
                        openPasswordPopup(false);
                      }}
                      className="cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </Layout>
      )}
    </>
  );
}

export default Dashboard;
