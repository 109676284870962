import { useState } from 'react'
import { Link } from 'react-router-dom'
import cmsService from '../services/cms.service'
import Layout from './Layout'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function ContactUs () {
  const [payloads, setPayloads] = useState({})
  const handleChange = e => {
    e.preventDefault()
    let name = e.target.name
    let value = e.target.value
    setPayloads(previousValue => {
      return { ...previousValue, [name]: value }
    })
  }

  const contactStored = async () => {
    if (
      payloads.email === null ||
      payloads.name === null ||
      payloads.phoneno === null ||
      payloads.content === null
    ) {
      toast.error('Required All Fields!')
    } else {
      try {
        let response = await cmsService.contactresponse(payloads)
        console.log('login', response.status)
        if (response.status) {
          toast.success('Submit Successfully!')
          setTimeout(() => {
            setPayloads({})
            window.location.reload(false)
          }, 1500)
        } else {
          toast.error(response.message)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  return (
    <Layout>
      <ToastContainer />
      <div className='inner-page-bg sction-padding'>
        <div className='container section-padding'>
          <h2 className='text-white fw-bold'>Contact Us</h2>
          <p className='text-secondary mt-2 mb-5'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <div className='row'>
            <div className='col-lg-7 '>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      First Name <span>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='User Name'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                      name='name'
                      onChange={e => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='col-lg-6 '>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      PhoneNumber <span>*</span>
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      placeholder='PhoneNumber'
                      aria-label='PhoneNumber'
                      aria-describedby='basic-addon2'
                      name='phoneno'
                      onChange={e => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      Trading Account Number <span>*</span>
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      placeholder='Trading Account Number'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                      // name='accountnumber'
                      // onChange={e => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      Email<span>*</span>
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      placeholder='email'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                      name='email'
                      onChange={e => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='mb-3 form-div '>
                    <label htmlFor='' className=''>
                      Descrition<span>*</span>
                    </label>
                    <textarea
                      className='form-control'
                      placeholder='Leave a comment here'
                      name='content'
                      onChange={e => handleChange(e)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className='mt-4'>
                <Link
                  onClick={() => {
                    contactStored()
                  }}
                  className='btn-1 w-100 text-center'
                >
                  Submit
                </Link>
              </div>
            </div>
            <div className='col-lg-5 mt-4 mt-lg-0'>
              <h4 className='text-white'>Address</h4>
              <p>55A North St, Sudbury, Suffolk County,United Kingdom</p>
              <h4 className='text-white'>Mail</h4>
              <p>sample@domain.com</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUs
