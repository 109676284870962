import '../../assets/PersonalInfo.css'

// framer motion
import { motion as m } from 'framer-motion'

export default function PersonalInfo({Field, values, errors, touched}) {
  const container ={
    hidden: {
        opacity:0,
    },
    show: {
        opacity:1,
        transition:{
          delay:.25,
        },
    },
}

  return (
    <m.div 
      className='personalInfo-form'
      variants={container}
      initial='hidden'
      animate='show'
    >
      <h2>Billing Details</h2>
      <p>Please provide your name, email address, and phone number.</p>

      
      <div className='row w-100'>
          <div className='col-lg-3'>
            <div className="input-container newch-form-div">
              <label className={`${touched.title && errors.title ? 'error-message' : ''}`} htmlFor="title">
                {touched.title && errors.title ? errors.title : 'Title'}
              </label>
              
              <Field 
                as="select" 
                name="title"
                className={`${touched.title && errors.title ? 'error-message-outline' : ''}`} value={values.title}>
                <option value="">Select Ttile</option>
                <option value="1">Mr.</option>
                <option value="2">Ms.</option>
                <option value="3">Mrs.</option>
                <option value="4">Mx.</option>
              </Field>
              
            </div>
          </div>
          <div className='col-lg-9'>
            <div className="input-container newch-form-div">
              <label className={`${touched.first_name && errors.first_name ? 'error-message' : ''}`} htmlFor="first_name">
                {touched.first_name && errors.first_name ? errors.first_name : 'First Name'}
              </label>
              <Field 
                type="text" 
                name='first_name' 
                placeholder='e.g. Stephen'
                className={`${touched.first_name && errors.first_name ? 'error-message-outline' : ''}`}
              />        
            </div>
          </div>
          <div className='col-lg-6 mt-4'>
            <div className="input-container newch-form-div">
              <label className={`${touched.last_name && errors.last_name ? 'error-message' : ''}`} htmlFor="last_name">
                {touched.last_name && errors.last_name ? errors.last_name : 'Last Name'}
              </label>
              <Field 
                type="text" 
                name='last_name' 
                placeholder='e.g. King'
                className={`${touched.last_name && errors.last_name ? 'error-message-outline' : ''}`}
              />        
            </div>
          </div>
          <div className='col-lg-6  mt-4'>
            <div className="input-container newch-form-div">
              <label className={`${touched.dob && errors.dob ? 'error-message' : ''}`} htmlFor="dob">
                {touched.dob && errors.dob ? errors.dob : 'Date of Birth'}
              </label>
              <Field 
                type="date" 
                name='dob' 
                className={`${touched.dob && errors.dob ? 'error-message-outline' : ''}`}
              />        
            </div>
          </div>
          <div className='col-lg-6  mt-4'>
            <div className="input-container newch-form-div">
              <label className={`${touched.street_address && errors.street_address ? 'error-message' : ''}`} htmlFor="street_address">
                {touched.street_address && errors.street_address ? errors.street_address : 'Street Address'}
              </label>
              <Field 
                type="text" 
                name='street_address' 
                placeholder='e.g. Main street'
                className={`${touched.street_address && errors.street_address ? 'error-message-outline' : ''}`}
              />        
            </div> 
          </div>
          <div className='col-lg-6 mt-4'>
            <div className="input-container newch-form-div">
              <label className={`${touched.city && errors.city ? 'error-message' : ''}`} htmlFor="city">
                {touched.city && errors.city ? errors.city : 'City'}
              </label>
              <Field 
                type="text" 
                name='city' 
                placeholder='e.g. London'
                className={`${touched.city && errors.city ? 'error-message-outline' : ''}`}
              />        
            </div>
          </div>
          <div className='col-lg-6 mt-4'>
            <div className="input-container newch-form-div">
              <label className={`${touched.postal_code && errors.postal_code ? 'error-message' : ''}`} htmlFor="postal_code">
                {touched.postal_code && errors.postal_code ? errors.postal_code : 'postal_code'}
              </label>
              <Field 
                type="number" 
                name='postal_code' 
                placeholder='e.g. 124578'
                className={`${touched.postal_code && errors.postal_code ? 'error-message-outline' : ''}`}
              />        
            </div>
          </div>
          <div className='col-lg-6 mt-4'>
            <div className="input-container newch-form-div">
              <label className={`${touched.country && errors.country ? 'error-message' : ''}`} htmlFor="country">
                {touched.country && errors.country ? errors.country : 'Country'}
              </label>
              <Field 
                type="test" 
                name='country' 
                placeholder='e.g. India'
                className={`${touched.country && errors.country ? 'error-message-outline' : ''}`}
              />        
            </div>
          </div>
      </div>

    </m.div>
  )
}
