import Layout from './Layout'
import { useState } from 'react'
import img1 from '../assets/images/landing-page/login/login-02.gif'
import img2 from '../assets/images/landing-page/login/login-lock.gif'
import { NavLink, Link } from 'react-router-dom'
import { useNavigate, createSearchParams } from 'react-router-dom'
import userService from '../services/user.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Login () {
  const [payloads, setPayloads] = useState({})
  let navigate = useNavigate()

  // const movePage = () => {
  //   navigate.push('/register')
  // }
  const userLogin = async () => {
    if (payloads.email === null || payloads.password === null) {
      toast.error('Required All Fields!')
    } else {
      try {
        let response = await userService.userLogin(payloads)
        if (
          response.status === true &&
          response.message !== 'Kindly check your mail for OTP' &&
          //response.message !== 'Email not found' &&
          response.message !== 'Email verification not completed'
        ) {
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('Gmail', payloads.email)
          toast.success('Login Successfully!')
          setTimeout(() => {
            navigate('/dashboard')
          }, 1000)
        } else if (
          // response.message === 'Email not found' ||
          response.message === 'Email verification not completed' ||
          response.message === 'Kindly check your mail for OTP'
        ) {
          console.log('EmailNotfound', response.message)
          localStorage.setItem('Gmail', payloads.email)
          toast.warning('Please Verify Registered Mail!')
          setTimeout(() => {
            navigate({
              pathname: '/otp',
              search: createSearchParams({
                foo: payloads.email
              }).toString()
            })
          }, 1000)
        } else {
          toast.error(response.message)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const recoveryPassword = () => {
    navigate('/forgotpassword')
  }

  const handleChange = e => {
    e.preventDefault()
    let name = e.target.name
    let value = e.target.value
    setPayloads(previousValue => {
      return { ...previousValue, [name]: value }
    })
  }
  return (
    <>
      {localStorage.getItem('token') === null ||
      localStorage.getItem('token') === undefined ? (
        <Layout>
          <ToastContainer />
          <div className='login-bg'>
            <div className='container'>
              <div className='row align-items-center position-relative justify-content-center'>
                <div className='col-lg-6 text-lg-start text-center wow animate__animated animate__fadeInDown  animate__slow'>
                  <h2 className='text-white fw-bold'>
                    Sign in to Direct Recharge
                  </h2>
                  <p className='mt-3'>If You Don't Have an Account </p>
                  <p className=''>
                    You Can{' '}
                    <span className='register-link'>
                      <Link to='/register'>Register Here!</Link>
                    </span>
                  </p>
                  <div className='position-relative login-element mx-auto mx-lg-0'>
                    <img src={img1} alt='' className='img-fluid' />
                    <div className='position-absolute top-0 bottom-0 start-0 end-0'>
                      <img
                        src={img2}
                        alt=''
                        className='img-fluid wow animate__animated animate__zoomIn  animate__slower animate__infinite'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='i mb-3 form-div'>
                    <label htmlFor='' className=''>
                      Email <span>*</span>
                    </label>
                    <input
                      type='text'
                      name='email'
                      onChange={e => {
                        handleChange(e)
                      }}
                      className='form-control'
                      placeholder='User Name'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                    />
                  </div>
                  <div className=' mb-3 form-div'>
                    <label htmlFor=''>
                      Password <span>*</span>
                    </label>
                    <input
                      name='password'
                      onChange={e => {
                        handleChange(e)
                      }}
                      type='password'
                      className='form-control'
                      placeholder='Password'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                    />
                  </div>
                  <div className='text-end'>
                    <span className='text-white' onClick={recoveryPassword}>
                      Recovery a Password
                    </span>
                  </div>
                  <div className='mt-4'>
                    <Link
                      onClick={userLogin}
                      className='btn-1 w-100 text-center'
                    >
                      Submit
                    </Link>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </Layout>
      ) : (
        <>
          {/* <Redirect to='/dashboard' /> */}
          <NavLink to='/dashboard' />
        </>
        // <Navigate to="/dashboard" replace={true} />
      )}
    </>
  )
}

export default Login
