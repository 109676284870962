import React from 'react'
import '../assets/Challenge.css'

import Plan from './steps/Plan'
import AddOn from './steps/AddOn'
import PersonalInfo from './steps/PersonalInfo'
import FinishingUp from './steps/FinishingUp'
import ThankyouPage from './steps/ThankyouPage'

//utilities 
import { useState, useEffect, useCallback } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'; 
import useRazorpay from "react-razorpay";
import * as Yup from 'yup'
import { toast } from 'react-toastify'

// framer motion
import { motion as m } from 'framer-motion'

// components
import {useApi} from '../hook/useApi'

function Challenge({ step, next, prev, goto}) {
    const [requestHit] = useApi();    
    const [Razorpay] = useRazorpay();  
    const randomKey = Math.floor(100000 + Math.random() * 9000).toString();

    const [validPlan, setValidPlan] = useState(null)
    const [challengeInfo, setChallengeInfo] = useState(null)

    const [couponList, setCouponList] = useState([{ coupon : "SCT002", discount: '10', applied : false }, 
    { coupon: "XYX00987", discount: "20", applied : false}, { coupon: "MUR987", discount: "50",  applied : false } ]);

    const btn ={
      hover: {
        scale:[null, 1.1, 1.05],
        transition:{
          duration: .2
        },
      },
      tap:{
        scale: .98,
      }    
    }

    useEffect(() => {
      getNewChallenge()
    }, [])

    const getNewChallenge =  useCallback(async()=>{
      const response = await requestHit('post', 'user/new-challenge', {
        type: '0'
      })
      if (response.status) {
        setChallengeInfo(response.data);
      } 
    }, [requestHit])

    const setPayment = async event => {
      let payload = await event()
      const response = await requestHit('post', 'user/add-challenge', payload) 
      if (response.status) {
        toast.success(response.message)
        next();
        // setTimeout(() => {
        //   navigate('/dashboard')
        // }, 2000)
      } else {
        toast.error(response.message)
      }
    }


    const paidViaRazorpay = async(values, couponList) => {

      const appliedCoupons = couponList.filter(coupon => coupon.applied);

      const totalDiscount = appliedCoupons.reduce((acc, coupon) => acc + parseInt(coupon.discount), 0);

      const payload = {
        platform: 'MatchTrader',
        tradingRules: values.tradingRules,
        account_type : values.account_type,
        account_size: values.account_size,
        account_phase: values.account_phase,
        price: values.account_price - totalDiscount,
        unique_key: randomKey
      }
      const response = await requestHit('post', 'user/createOrder',  payload )
      if (response.status) {
        toast.success(response.message)
        if (response.data.order_id) {      
            const options = {
              key: process.env.REACT_APP_RAZORPAY_ID,
              key_secret: process.env.REACT_APP_RAZORPAY_SECRET,
              amount: values.account_price - totalDiscount,
              currency: process.env.REACT_APP_CURRENCY,
              order_id: response.data.order_id,
              name: process.env.REACT_APP_NAME,
              description: "Account Purchase",
              // image: logo,
              handler: (res) => {
                if (res) {
                  setPayment(()=>{
                    return { ...values, 'paid_via' : 'razor_pay', 'payment_details' : res, 'coupons_used': appliedCoupons, price: values.account_price - totalDiscount };
                  })
                }
              },
              prefill: {
                name: "My Prop Funding",
                email: "mpfadmin@yopmail.com",
                contact: "9999999999",
              },
              notes: {
                address: "MPF Admin Office",
              },
              theme: {
                color: "#00b5b1",
              },
            };
  
            const rzpay = new Razorpay(options);
            rzpay.open();
        }
      } else {
        toast.error(response.message)
      }
    }; 
    
  
    const paidViaCrypto = async(values, coupenList)=>{  

      const appliedCoupons = couponList.filter(coupon => coupon.applied);

      const totalDiscount = appliedCoupons.reduce((acc, coupon) => acc + parseInt(coupon.discount), 0);

      const payload = {
        platform: 'MatchTrader',
        tradingRules: values.tradingRules,
        account_type : values.account_type,
        account_size: values.account_size,
        account_phase: values.account_phase,
        price: values.account_price - totalDiscount,
        unique_key: randomKey
      }


  
      const response = await requestHit('post', 'user/cryptoPayment',  payload )
      if (response.status) {      
        setPayment(()=>{
          return { ...values, 'paid_via' : 'crypto_pay', 'payment_details' : response, 'coupons_used': appliedCoupons, price: values.account_price - totalDiscount  }
        });
      }else {
        toast.error(response.message)
      }
    }

    return (
      <>
      {
        challengeInfo ? (
          <Formik
            initialValues={{
              platform: 'MatchTrader',
              title: challengeInfo ? challengeInfo.personal_details?.title : '',
              first_name: challengeInfo ? challengeInfo.personal_details?.first_name : '',
              last_name: challengeInfo ? challengeInfo.personal_details?.last_name : '',
              dob: challengeInfo ? challengeInfo.personal_details?.dob : '',
              street_address: challengeInfo ? challengeInfo.personal_details?.street_address : '',
              city: challengeInfo ? challengeInfo.personal_details?.city : '',
              postal_code: challengeInfo ? challengeInfo.personal_details?.postal_code : '',
              country: challengeInfo ? challengeInfo.personal_details?.country : '',
              account_type: '0',
              account_phase: '0',
              account_price: '',
              account_size: '',
              plan:'',
              isOn: 'false', 
              checked: []
            }}
            validationSchema = {Yup.object({
              title: Yup.number()
                .required('Ttle is required.'),
    
              first_name: Yup.string()
                .max(20, 'First Name must be 20 characters or less.')
                .required('First Name is required.'),
    
              last_name: Yup.string()
                .max(20, 'Last Name must be 20 characters or less.')
                .required('Last Name is required.'),
    
              dob: Yup.date()
                .required('Email is required.'),
    
              street_address: Yup.string()
                .max(50, 'Street Address must be 50 characters or less.')
                .required('Street Address must is required.'),
    
              city: Yup.string()
                .max(20, 'City be 20 characters or less.')
                .required('City must is required.'),
              
              postal_code: Yup.number()
                .min(6, 'Postal Code must be 6 digit or more.')
                .required('Postal Code is required.'),
    
              country: Yup.string()
                .max(20, 'Country be 20 characters or less.') 
                .required('Country must is required.'),
                
              account_type : null,
    
              account_phase : null ,
    
              account_size : validPlan ? Yup.string().required('Please select a plan.') : null,
    
              account_price : validPlan? null : null,
            })}
    
            onSubmit={ (values, {resetForm}) => {
    
              // not the best solution to the problem. if one has not selected a plan and decides to go the previous page. they wont be able to go to the next page as the error of not selecting a plan wont allow the form to submit. :/
              if(step === 0){
                setValidPlan(true)
              }

              if (step !== 4) return next()
              
            }}
          >
            {({values, errors, touched}) => (
            
              <Form>
                {step === 0 && <PersonalInfo 
                  Field={Field} 
                  errors={errors} 
                  touched={touched} 
                  values={values}
                  ErrorMessage={ErrorMessage}/>}
                
                {step === 1 && <Plan 
                  Field={Field} 
                  values={values}
                  errors={errors} 
                  touched={touched}
                  planList={challengeInfo.account_type_details}
                  ErrorMessage={ErrorMessage}/>}
    
                {step === 2 && <AddOn 
                  Field={Field} 
                  values={values}
                  errors={errors} 
                  touched={touched}
                  couponList={couponList} 
                  setCouponList={setCouponList} />}
    
                {step === 3 && <FinishingUp 
                  values={values}
                  goto={goto}              
                  couponList={couponList}  
                  // SetPayment={setPayment}
                  />}
    
                {step === 4 && <ThankyouPage goto={goto}/>}
              
                
                <m.div 
                  className="btn-container"
                  initial={{opacity:0}}
                  animate={{opacity:1}}
                  transition={{delay:.5}}
                >
                  {step !== 0 && step !== 4 && <m.button 
                    className='prev-btn' 
                    type='button' 
                    variants={btn}
                    whileHover='hover'
                    whileTap='tap'
                    onClick={prev}
                  >Go Back</m.button>}
    
                  {step === 0 && <div></div>}
                  <div>
                  { step < 3 && <m.button 
                    className='primary-btn btn-sm py-1 px-2 mt-0' 
                    type='submit'
                    variants={btn}
                    whileHover='hover'
                    whileTap='tap'
                  >Next Step</m.button>}
                 

                  
                  { step === 3 && 
                    <>
                      <m.button 
                          className='primary-btn btn-sm py-1 px-2  mt-0' 
                          type='button'
                          variants={btn}
                          whileHover='hover'
                          whileTap='tap'
                          onClick={()=>{
                            paidViaRazorpay(values, couponList)
                          }}
                      >Pay Via Razor Pay</m.button>
                      <m.button 
                        className='primary-btn btn-sm py-1 px-2  mt-0 ms-2' 
                        type='button'
                        variants={btn}
                        whileHover='hover'
                        whileTap='tap'
                        onClick={()=>{
                          paidViaCrypto(values, couponList)
                        }}
                      >Pay Via Crypto</m.button>
                    </>                    
                  }
    </div>
                  
                </m.div>
              </Form>
            )}
          </Formik>
        ) : ( <></> )
      }
      </>     
    
  )
}

export default Challenge
