import Layout from './Layout'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import userService from '../services/user.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createSearchParams, useNavigate } from 'react-router-dom'

function ForgotPassword (props) {
  const navigate = useNavigate()
  const [getEmail, setEmail] = useState('')
  const forgotpassword = async () => {
    if (getEmail === null || getEmail === '' || getEmail === undefined) {
      toast.error('Required Email!')
    } else {
      try {
        let data = {
          email: getEmail
        }
        let response = await userService.forgotPassword(data)
        if (response.status) {
          localStorage.setItem('Gmail', getEmail)
          navigate({
            pathname: '/resetpassword',
            search: createSearchParams({
              foo: getEmail
            }).toString()
          })
        } else {
          toast.error(response.message)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  return (
    <Layout>
      <ToastContainer />
      <div className='login-bg'>
        <div className='container'>
          <div className='row align-items-center position-relative justify-content-center'>
            <div className='col-lg-5 wow animate__animated animate__fadeInDown  animate__slow'>
              <div className='card-div p-5'>
                <h2 className='text-white fw-bold'>Forgot Password</h2>
                <p className='mt-3'>
                  Tell us you email address,and we'll get you back on track in
                  to time
                </p>
                <div className='i mb-3 form-div'>
                  <label htmlFor='' className=''>
                    Email <span>*</span>
                  </label>
                  <input
                    type='email'
                    onChange={e => {
                      setEmail(e.target.value)
                    }}
                    className='form-control'
                    placeholder='Email'
                    aria-label='User Name'
                    aria-describedby='basic-addon2'
                  />
                </div>
                <div className='mt-4'>
                  <Link
                    className='btn-1 w-100 text-center'
                    onClick={forgotpassword}
                  >
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ForgotPassword
