
// styles 
import '../../assets/AddOn.css'

// framer motion
import { motion as m } from 'framer-motion'

export default function AddOn({Field, values,couponList, setCouponList}) {

  const handleCouponChange = (index, status)=>{
    couponList[index].applied = status
    setCouponList(couponList);
  }

  const container ={
    hidden: {
        opacity:0,
    },
    show: {
        opacity:1,
    },
  }

  const addOn ={
    hover: {
      scale:[null, 1.08, 1.02],
      transition:{
        duration: .2,
      },
      outline: '1px solid #473dff'
    },
    tap:{
      scale: .99999,
    }    
  }

  
  return (
    <m.div 
      className='addOn-form'
      variants={container}
      initial='hidden'
      animate='show'
    >
      <h2>Available Coupons</h2>
      <p>Apply Coupons</p>

      {
        couponList.map((item, index)=>(
          // <m.label 
          //   style={{
          //     outline: (item.applied) ? '1px solid #473dff' : '',
          //     backgroundColor: (item.applied) ? '#473dff1a' : '' 
          //   }}
          <div
            className='card-div newch-third'
            htmlFor={item.coupon}
            variants={addOn}
            whileHover='hover'
            whileTap='tap'
            key={index}
            onChange={()=>{
              handleCouponChange(index, !item.applied)
            }}
          >
            <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <Field
                  id='online'
                  type="checkbox" 
                  name="coupon" 
                  value={item.discount}
                />
                <div className='d-flex ms-3'>
                  <h5  className='mb-0 text-white'>{ item.discount }</h5>
                  <p className='mb-0 ms-1'>Description of coupon</p>
                </div>
              </div>
              <h4  className='mb-0'>{values.account_price - item.discount}</h4>
            </div>
            
            
            </div>
          // </m.label>
        ))
      }
       
    </m.div>
  )
}
