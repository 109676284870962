import Layout from './Layout'
import { Link, useNavigate } from 'react-router-dom'
import author from '../assets/images/dashboard/author.png'
function Account () {
  let navigate = useNavigate()
  const signout = e => {
    e.prevendDefault()
    localStorage.clear()
    navigate('/login')
  }
  return (
    <Layout>
      <div className='container-fluid inner-page-bg-1'>
        <div className='row'>
          <div className='col-lg-3'>
            <div className='page-leftsidebar mt-3'>
              <ul className='nav nav-pills nav-flush mb-auto text-center'>
                <li>
                  {/* <a href="new-challange.html" className="text-decoration-none"> */}
                  <Link
                    className='text-decoration-none'
                    aria-current='page'
                    to='/newchallenge'
                  >
                    <div className='anim-btn'>
                      <span>New Challenge</span>

                      <i className='ico'>
                        {' '}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='#fff'
                          className='bi bi-patch-plus-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.916 2.916l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.916 2.916l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.916-2.916l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.916-2.916l-.89.01-.622-.636zM8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0z' />
                        </svg>
                      </i>
                    </div>
                    {/* </a> */}
                  </Link>
                </li>
                <li>
                  {/* <a href="account-details.html" className="text-decoration-none"> */}
                  <Link
                    className='text-decoration-none'
                    aria-current='page'
                    to='/accountdetails'
                  >
                    <div className='anim-btn'>
                      <span>Dashboard</span>

                      <i className='ico'>
                        {' '}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='#fff'
                          className='bi bi-house-door-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M6.5 14.5v-3.505c0-.165.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z' />
                        </svg>
                      </i>
                    </div>
                    {/* </a> */}
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none'>
                    <div className='anim-btn'>
                      <span>Payouts</span>

                      <i className='ico'>
                        {' '}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='#fff'
                          className='bi bi-credit-card-2-back-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z' />
                        </svg>
                      </i>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none'>
                    <div className='anim-btn'>
                      <span>Orders</span>

                      <i className='ico'>
                        {' '}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='#fff'
                          className='bi bi-dice-6-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm1 5.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1.5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM12 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5.5 12a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM4 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z' />
                        </svg>
                      </i>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none'>
                    <div className='anim-btn'>
                      <span>Leader Board</span>

                      <i className='ico'>
                        {' '}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='#fff'
                          className='bi bi-globe-americas'
                          viewBox='0 0 16 16'
                        >
                          <path d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.162.234-.416.396-.787.749-.758 1.266.035.634.618.816 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.163-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z' />
                        </svg>
                      </i>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className='text-decoration-none'>
                    <div className='anim-btn'>
                      <span>Calender</span>

                      <i className='ico'>
                        {' '}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='#fff'
                          className='bi bi-calendar-fill'
                          viewBox='0 0 16 16'
                        >
                          <path d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z' />
                        </svg>
                      </i>
                    </div>
                  </Link>
                </li>

                <li className='position-absolute logout-icon'>
                  <div className='dropdown'>
                    <Link
                      className='d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none dropdown-toggle'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='#fff'
                        className='bi bi-person-lines-fill'
                        viewBox='0 0 16 16'
                      >
                        <path d='M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z' />
                      </svg>
                    </Link>
                    <ul className='dropdown-menu text-small shadow'>
                      <li>
                        <Link className='dropdown-item'>New project...</Link>
                      </li>
                      <li>
                        <Link to='/acc_setting' className='dropdown-item'>
                          Settings
                        </Link>
                      </li>
                      <li>
                        <Link className='dropdown-item'>Profile</Link>
                      </li>
                      <li>
                        <hr className='dropdown-divider' />
                      </li>
                      <li>
                        <Link
                          className='dropdown-item'
                          onClick={e => signout(e)}
                        >
                          Sign out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className='row dash-top mb-5'>
              <div className='col-lg-5'>
                <div className='h-100'>
                  <div className='card-div'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='i mb-3 form-div'>
                          <select
                            className='form-select'
                            aria-label='Default select example'
                          >
                            <option selected>All Accounts</option>
                            <option value='1'>FP Evaluation</option>
                            <option value='2'>Algo</option>
                            <option value='3'>Competitions</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='i mb-3 form-div'>
                          <select
                            className='form-select'
                            aria-label='Default select example'
                          >
                            <option selected>All</option>
                            <option value='1'>New Going</option>
                            <option value='2'>Not Passed</option>
                            <option value='3'>Passed</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='i mb-3 form-div'>
                          <select
                            className='form-select'
                            aria-label='Default select example'
                          >
                            <option selected>All Accounts</option>
                            <option value='1'>FP Evaluation</option>
                            <option value='2'>Algo</option>
                            <option value='3'>Competitions</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <Link className='primary-btn w-100 text-center mt-3'>
                      New Challenge
                    </Link>
                  </div>
                  <div className=' card-div mt-4'>
                    <div className='d-flex align-items-center'>
                      <div>
                        <img
                          src={author}
                          alt='author'
                          width='64px'
                          height='64px'
                        />
                      </div>
                      <div className='text-center ms-3'>
                        <h4 className='text-white'>Name</h4>
                        <p>Student</p>
                      </div>
                    </div>
                    <div className='border-top mt-2 pt-2'>
                      <h6 className='text-secondary-emphasis mb-3'>
                        No.Of Trades : 01
                      </h6>
                      <h6 className='text-secondary-emphasis'>
                        Days Traded : 01
                      </h6>
                    </div>
                    <div className='d-flex mt-3 justify-content-between'>
                      <Link className='btn-1 btn-sm'>Credentials</Link>
                      <a
                        href='single-dashboard.html'
                        className='primary-btn btn-sm'
                      >
                        Dashboard
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className='card-div mt-3 d-flex justify-content-between text-center'>
                      <div>
                        <span className='text-secondary'>Account Balance</span>
                        <h5 className='text-white'>$5000</h5>
                      </div>
                      <div>
                        <span className='text-secondary'>Account Win</span>
                        <h5 className='text-success'>$0</h5>
                      </div>
                      <div>
                        <span className='text-secondary'>Account Loss</span>
                        <h5 className='text-danger'>$0</h5>
                      </div>
                      <div>
                        <span className='text-secondary'>Win ratio</span>
                        <h5 className='text-white'>0%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-7'>
                <div className='h-100'>
                  <div className='card-div h-10'>
                    <div>
                      <h3 className='text-white'>Account Overview</h3>
                      <p className='mb-1'>#529730</p>
                      <span className='text-secondary'>
                        Created: Oct 4, 2023 11:57 AM
                      </span>
                    </div>

                    <h6 className='mt-5 text-white'>
                      <div>
                        {/* <canvas id="barChart"></canvas> */}
                        {/* <BarChart /> */}
                      </div>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Account
