import axios from 'axios'
import http_common from '../http_common'

const userLogin = async data => {
  try {
    let response = await axios.post(`${http_common}user/login`, data)
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}

const userRegister = async data => {
  try {
    let response = await axios.post(`${http_common}user/register`, data)
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}

const forgotPassword = async data => {
  try {
    let response = await axios.post(`${http_common}user/forget-password`, data)
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}

const resetPassword = async data => {
  try {
    let response = await axios.post(`${http_common}user/reset-password`, data)
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}

const verifyAccount = async data => {
  try {
    let response = await axios.post(`${http_common}user/verify-account`, data)
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}

const userPasswordreset = async dataPayload => {
  try {
    let temptoken = localStorage.getItem('token')
    const config = {
      headers: {
        Authorization: `Bearer ${temptoken}`
      }
    }

    let response = await axios.post(
      `${http_common}user/user-password-reset`,
      dataPayload,
      config
    )
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}

const createPayout = async payloads => {
  try {
    let temptoken = await localStorage.getItem('token')
    const config = {
      headers: {
        Authorization: `Bearer ${temptoken}`
      }
    }
    const response = await axios.post(
      `${http_common}user/create-payout`,
      config,
      payloads
    )
    return response.data
  } catch (error) {
    return {
      status: false,
      message: error.message ? error.message : 'Some error occured',
      data: null
    }
  }
}

const getPayout = async () => {
  try {
    let temptoken = await localStorage.getItem('token')
    const config = {
      headers: {
        Authorization: `Bearer ${temptoken}`
      }
    }
    const response = await axios.post(
      `${http_common}user/get-payouts`,
      config
      //payloads
    )
    return response.data
  } catch (error) {}
}

const user = {
  userLogin,
  userRegister,
  forgotPassword,
  resetPassword,
  verifyAccount,
  userPasswordreset,
  createPayout,
  getPayout
}

export default user
