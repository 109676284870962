import { useEffect, useState } from 'react'
import Layout from './Layout'
import cmsService from '../services/cms.service'
import { Link } from 'react-router-dom'
import {useApi} from '../hook/useApi'

function Faq () {
  const [requestHit] = useApi();
  const [faqList, setFaqList] = useState([])  
  useEffect(() => {
    const faqresponse = async () => {
        requestHit('get', 'user/getFaqList').then(response => {
          if (response.status) {            
            setFaqList(response.data)
          }
        })  
    }
    faqresponse()
  }, [])

  return (
    <Layout>
      <div className='inner-page-bg sction-padding'>
        <div className='container section-padding'>
          <h2 className='text-white fw-bold'>FAQ</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <div>

          {
            faqList?.map((faq, index)=>(
              <div key={index} className='mt-4'>              
                <h5 className='text-white'>
                  {faq.question}
                </h5>
                <span className='text-secondary'>
                {faq.content}
                </span>
                {
                  (faq.content.length > 150) ? (
                    <Link
                      to='/singlefaq'
                      state={faqList}
                      className='primary-btn btn-sm ms-3'
                    >
                      Read More
                    </Link>
                  ) : (<></>)
                }
            </div>
            ))
          }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Faq
