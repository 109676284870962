import React, { useState, useEffect } from 'react'
import SubHeader from './SubHeader'
import Layout from './Layout'
import { Link, useNavigate} from 'react-router-dom'
import '../assets/ListAndAdd.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NavLink } from 'react-router-dom'
import {useApi} from '../hook/useApi'

const Payout = () => {

  const navigate = useNavigate();

  const [kycDetails, setKycDetails] = useState({})


  useEffect(()=>{    
    const kycTemp = localStorage.getItem('KYCDETAIL');
    setKycDetails(JSON.parse(kycTemp))
  },[]);
  
  const handleValuechange = (event)=> {
    setKycDetails((previous)=>{
      return { ...previous, [event.target.name]: event.target.value }
    })
  }

  const handleSubmit = ()=>{
    const kycDetailSaved = JSON.parse(localStorage.getItem("KYCDETAIL"))
    localStorage.setItem('KYCDETAIL', JSON.stringify({ ...kycDetailSaved, ...kycDetails }));
    navigate('/kycStepThree');
  }
  
  return (
    <>
      {localStorage.getItem('token') !== null ||
      localStorage.getItem('token') !== undefined ? (
        <Layout>
          <div className='container-fluid  inner-page-bg'>
            <div className='row'>
            <SubHeader />
              <div className='col-lg-9 mt-4'>
                <div className='card-div p-4 kyc-form-group'>
                    <h3 className='text-white text-center'>Profile </h3>
                    <h5 className='text-white mt-3'>Address Details </h5>
                    <div className='row mt-4'>
                      <div className='col-lg-12'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                             Street Address <span>*</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Street Address'
                            aria-label='User Name'
                            aria-describedby='basic-addon2'
                            name='street_address'    
                            value={kycDetails.street_address}                        
                            onChange={(e)=> { handleValuechange(e) } }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                            City <span>*</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter the City'
                            aria-label='Last Name'
                            aria-describedby='basic-addon2'
                            name='city'                                
                            value={kycDetails.city}  
                            onChange={(e)=> { handleValuechange(e) } }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                            State <span>*</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter the State'
                            aria-label='Last Name'
                            aria-describedby='basic-addon2'
                            name='state'               
                            value={kycDetails.state}  
                            onChange={(e)=> { handleValuechange(e) } }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                            Zip Code <span>*</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Postal / Zip code'
                            aria-label='Last Name'
                            aria-describedby='basic-addon2'
                            name='zipcode'        
                            value={kycDetails.zipcode}  
                            onChange={(e)=> { handleValuechange(e) } }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                          Country <span>*</span>
                          </label>
                          <select className="form-select" aria-label="Default select example" value={kycDetails.country_name} name='country_name' onChange={(e)=> { handleValuechange(e) } }>
                            <option value="">Select the Country</option>
                            <option value="India">India</option>
                            <option value="America">America</option>
                            <option value="Australia">Australia</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                            Phone Number <span>*</span>
                          </label>
                          <input
                            type='Number'
                            className='form-control'
                            placeholder='Phone Number'
                            aria-label='Last Name'
                            aria-describedby='basic-addon2'
                            value={kycDetails.contact}  
                            name='contact' onChange={(e)=> { handleValuechange(e) } }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                            Mail <span>*</span>
                          </label>
                          <input
                            type='email'
                            className='form-control'
                            placeholder='Mail'
                            aria-label='Last Name'
                            aria-describedby='basic-addon2'
                            value={kycDetails.mail_id}  
                            name='mail_id' onChange={(e)=> { handleValuechange(e) } }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 mt-4'>
                        <div className='mb-3 form-div'>
                          <label htmlFor='' className=''>
                          ID Proof <span>*</span>
                          </label>
                          <select className="form-select" aria-label="Default select example" value={kycDetails.id_proof} name='id_proof'  onChange={(e)=> { handleValuechange(e) } } >
                            <option value="">Select the ID Proof</option>
                            <option value="Voter Id">Voter Id</option>
                            <option value="Driving License">Driving License</option>
                            <option value="Passport">Passport</option>
                            <option value="National ID">National ID</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='mt-4 text-end'>
                        <Link to='/kycstepone'>
                          <button className="primary-btn btn-sm">
                            Back
                          </button>
                        </Link>
                        {/* <Link to='/kycstepthree'> */}
                          <button className="primary-btn btn-sm ms-3"  onClick={(e)=> { handleSubmit() }}>
                            next
                          </button>
                        {/* </Link> */}
                      </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      ) : (
        <>
          <NavLink to='/login' />
        </>
      )}
    </>
  )
}

export default Payout
