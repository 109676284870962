import Layout from './Layout'
import img1 from '../assets/images/landing-page/login/register.gif'
import userService from '../services/user.service'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createSearchParams, useNavigate, Link} from 'react-router-dom'

function Register () {
  const [payloads, setPayloads] = useState({})
  const navigate = useNavigate()
  const userRegister = async e => {
    if (
      payloads.first_name === null ||
      payloads.last_name === null ||
      payloads.phonenumber === null ||
      payloads.email === null ||
      payloads.password === null ||
      payloads.confirm_password === null
    ) {
      toast.error('Required All Fields!')
    } else {
      try {
        e.preventDefault()
        let userregisterResponse = await userService.userRegister(payloads)
        if (userregisterResponse.status) {
          localStorage.setItem('Gmail', payloads.email)
          toast.success('OTP sent Successfully!')
          setTimeout(() => {
            navigate({
              pathname: '/otp',
              search: createSearchParams({
                foo: payloads.email
              }).toString()
            })
          }, 2000)
        } else {
          toast.error(userregisterResponse.message)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const handleChange = e => {
    e.preventDefault()
    let name = e.target.name
    let value = e.target.value
    setPayloads(previousValue => {
      return { ...previousValue, [name]: value }
    })
  }
  return (
    <Layout>
      <ToastContainer />
      <div className='login-bg'>
        <div className='container'>
          <div className='row align-items-center position-relative justify-content-center'>
            <div className='col-lg-5 text-lg-start text-center wow animate__animated animate__fadeInDown  animate__slow order-lg-2'>
              <h2 className='text-white fw-bold'>Register</h2>
              <p className='mt-3'>
                Reference site about Lorem Ipsum, giving information on its
                origins, as well as a random Lipsum generator.{' '}
              </p>
              <div className='position-relative login-element text-center mx-auto'>
                <img src={img1} alt='' className='img-fluid' />
              </div>
            </div>
            <div className='col-lg-6 order-lg-1'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      First Name <span>*</span>
                    </label>
                    <input
                      name='first_name'
                      onChange={e => handleChange(e)}
                      type='text'
                      className='form-control'
                      placeholder='User Name'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      Last Name <span>*</span>
                    </label>
                    <input
                      name='last_name'
                      onChange={e => handleChange(e)}
                      type='text'
                      className='form-control'
                      placeholder='Last Name'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      Phone Number <span>*</span>
                    </label>
                    <input
                      name='phonenumber'
                      onChange={e => handleChange(e)}
                      type='number'
                      className='form-control'
                      placeholder='Phone Number'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      Email<span>*</span>
                    </label>
                    <input
                      name='email'
                      onChange={e => handleChange(e)}
                      type='email'
                      className='form-control'
                      placeholder='email'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      Password<span>*</span>
                    </label>
                    <input
                      name='password'
                      onChange={e => handleChange(e)}
                      type='password'
                      className='form-control'
                      placeholder='Password'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3 form-div'>
                    <label htmlFor='' className=''>
                      Confirm Password<span>*</span>
                    </label>
                    <input
                      name='confirm_password'
                      onChange={e => handleChange(e)}
                      type='password'
                      className='form-control'
                      placeholder='Conforim Password'
                      aria-label='User Name'
                      aria-describedby='basic-addon2'
                    />
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexCheckChecked'
                      checked
                    />
                    <label
                      className='form-check-label text-white'
                      htmlFor='flexCheckChecked'
                    >
                      I agree to the all Term and Conditions
                    </label>
                  </div>
                </div>
              </div>
              <div className='mt-4'>
                <Link
                  className='btn-1 w-100 text-center'
                  onClick={e => {
                    userRegister(e)
                  }}
                >
                  Submit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Register
