import React, { useState, useEffect } from 'react'
import SubHeader from './SubHeader'
import Layout from './Layout'
import '../assets/ListAndAdd.css'
import 'react-toastify/dist/ReactToastify.css'
import { NavLink } from 'react-router-dom'
import {useApi} from '../hook/useApi'

const Payout = () => {
  const [requestHit] = useApi();

  const typesList = ['Learner', 'Performer', 'Expert'];
  const phaseList = ['Regular', 'Super'];

  const [invoiceList, setInvoiceList] = useState([])

  useEffect(() => {
    requestHit('get', 'user/getInvoice').then(response => {
      if (response.status) {
        setInvoiceList(response.data)
      }
    })
  }, [])

  return (
    <>
      {localStorage.getItem('token') !== null ||
      localStorage.getItem('token') !== undefined ? (
        <Layout>
          <div className='container-fluid  inner-page-bg'>
            <div className='row'>
            <SubHeader />
              <div className='col-lg-9 mt-4'>
                <h4 className='text-white'>Invoice List</h4>
                <table className='table primary-table mt-4'>
                  <thead>
                    <tr className='table-heading'>
                      <th scope="col">#</th>
                      <th scope="col">Account Type</th>
                      <th scope="col">Account Phase</th>
                      <th scope="col">Size</th>
                      <th scope="col">Price</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      invoiceList.map((data, index)=>(
                        <tr key={index}>
                          <th scope="row">{data.unique_key}</th>
                          <td>{typesList[data.order_detail.account_type ? data.order_detail.account_type : 0]}</td>
                          <td>{phaseList[data.order_detail.account_phase ? data.order_detail.account_phase : 0]}</td>
                          <td>{data.order_detail.account_size ? data.order_detail.account_size : 5000 }</td>
                          <td>$ {data.order_detail.account_price ? data.order_detail.account_price : 10}</td>
                          <td>{data.createdAt}</td>
                          <td>{data.status === 0 ? 'Paid' : 'Pending'}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          

           
          </div>
        </Layout>
      ) : (
        <>
          <NavLink to='/login' />
        </>
      )}
    </>
  )
}

export default Payout
