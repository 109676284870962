// styles
import '../../assets/Plan.css'

//images 
import Arcade from '../../assets/icon-arcade.svg'
// components
import Toggle from './Toggle'

// framer motion
import { motion as m } from 'framer-motion'
import { AnimatePresence } from 'framer-motion'


export default function Plan({Field, values, errors, touched, ErrorMessage, planList}) {

  const choiceList = planList.account_sizes[values.account_phase];
  const container ={
    hidden: {
        opacity:0,
        
    },
    show: {
        opacity:1,
    },
  }
  const card ={
    hover: {
      scale:[null, 1.1, 1.05],
      transition:{
        duration: .2,
      },
      outline: '1px solid hsl(var(--purpleish-blue))'
    },
    tap:{
      scale: .98,
    },
  }

  const handleSizeChange = (index)=>{
    const selectedPlan = planList.account_sizes[values.account_phase][index]
    values.account_price = selectedPlan.charge;
    values.tradingRules = {
      account_type: planList.account_type,
      duration: planList.duration,
      type: planList.type,
      accountSize: selectedPlan
    };
  }

  return (
    <m.div 
      className='plan-form'
      variants={container}
      initial='hidden'
      animate='show'
    >
      <h2>Challenge Details</h2>

      <div className="radio-container">
        {
          choiceList.map((item, index)=>(
            <div className='card-div' key={index} onClick={()=> { handleSizeChange(index) }}>
              
                <div className='d-flex mb-2'>
                <Field 
                  type="radio" 
                  name='account_size'
                  id={''+item.size}
                  value={''+item.size}
                />
                <img  className='ms-2' src={Arcade} alt={item.size} />
                </div>
                
                {/* <m.label 
                  variants={card} 
                  whileHover='hover'
                  whileTap='tap'
                  htmlFor={item.size}
                > */}
                 
                  <div>
                    <strong>{item.size}</strong>
                    <span className='item-charge'><span className='mx-2'>/</span>${item.charge}</span>
                    <AnimatePresence 
                      htmlFor={item.size}>
                        <m.small
                        initial={{
                          y:'-10px',
                          opacity:0
                        }} 
                        animate={{
                          y:'0px',
                          opacity:1
                        }}
                        exit={{
                          y:'-10px',
                          opacity:0,
                          transition:{duration:0.3, type:'spring'}
                        }} 
                        htmlFor={item.size}
                        >
                          <div>
                            <ul className='newch-list'>
                              <li>
                                  <h6><div>Daily Loss Limit :</div><div> {item.daily_loss_limit}</div></h6>
                                  <h6><div>Max Loss Limit :</div><div>{item.max_loss_limit}</div></h6>
                                  <h6><div>Min Trade Days :</div><div> {item.minimum_trade_days}</div></h6>
                                  <h6><div>Profit Target :</div> <div>{item.profit_target}</div></h6>
                              </li>
                            </ul>
                          </div>
                        </m.small>
                    </AnimatePresence>
                  </div>
                {/* </m.label> */}
             
            </div>
          ))
        }     
      </div>
      
      {/* <Toggle Field={Field} values={values}/> */}

      <AnimatePresence>
      <ErrorMessage name='account_size'>{msg => 
          <m.p
            initial={{opacity:0}}
            animate={{opacity:1}}
            end={{opacity:0}}
            className={`${touched.account_size && errors.account_size ? 'error-message' : ''}`}
          >{msg}</m.p>
        }
        </ErrorMessage>
      </AnimatePresence>
    </m.div>
  )
}
