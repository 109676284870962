import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Layout from './Layout'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import userService from '../services/user.service'
import { useNavigate } from 'react-router-dom'

const SettingsPage = () => {
  const navigate = useNavigate()
  const [payload, setPayload] = useState({
    email: localStorage.getItem('Gmail')
  })

  const handlePassword = e => {
    let name = e.target.name
    let value = e.target.value
    setPayload(pre => {
      return { ...pre, [name]: value }
    })
  }

  const handleChangePassword = async e => {
    e.preventDefault()
    if (payload.new_password !== payload.confirmpassword) {
      toast.warning('Password does not matched')
    } else {
      try {
        let { confirmpassword, ...payloads } = payload
        let response = await userService.userPasswordreset(payloads)
        if (response.status) {
          toast.success(response.message)
          setTimeout(() => {
            navigate('/dashboard')
          }, 1000)
        } else {
          toast.error(response.message)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  return (
    <>
      {localStorage.getItem('token') === null ||
      localStorage.getItem('token') === undefined ? (
        <NavLink to='/login' />
      ) : (
        <Layout>
          <ToastContainer />
          <div>
            <div className='container-fluid inner-page-bg'>
              <div className='row'>
                <div style={styles.container}>
                  <h2 style={styles.heading}>Change Password</h2>
                  <form style={styles.form}>
                    <input
                      placeholder='Old Password'
                      type='password'
                      id='currentPassword'
                      name='old_password'
                      onChange={e => {
                        handlePassword(e)
                      }}
                      style={styles.input}
                    />
                    <input
                      placeholder='New Password'
                      type='password'
                      id='newPassword'
                      name='new_password'
                      onChange={e => {
                        handlePassword(e)
                      }}
                      style={styles.input}
                    />
                    <input
                      placeholder='Confirm Password'
                      type='password'
                      id='confirmPassword'
                      name='confirmpassword'
                      onChange={e => {
                        handlePassword(e)
                      }}
                      style={styles.input}
                    />
                    <button
                      type='button'
                      onClick={e => {
                        handleChangePassword(e)
                      }}
                      style={styles.button}
                    >
                      Change Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  )
}

const styles = {
  container: {
    maxWidth: '500px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff'
  },
  heading: {
    textAlign: 'center',
    color: '#333'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px'
  },
  formGroup: {
    marginBottom: '15px'
  },
  label: {
    marginBottom: '5px',
    color: '#555'
  },
  input: {
    marginTop: '10px',
    padding: '8px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '3px'
  },
  button: {
    marginTop: '10px',
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer'
  }
}

export default SettingsPage
