import Layout from './Layout'
import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import userService from '../services/user.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Otp (props) {
  const [getOtp, setOtp] = useState('')
  //let navigate = useHistory()
  let navigate = useNavigate()
  const otpVerify = async () => {
    if (getOtp === null || getOtp === undefined || getOtp === '') {
      toast.error('Required All Field!')
    } else {
      try {
        let data = {
          otp: getOtp,
          email: localStorage.getItem('Gmail')
        }
        let otpresponses = await userService.verifyAccount(data)
        if (otpresponses.status) {
          localStorage.clear()
          toast.success('Verified Successfully!')
          setTimeout(() => {
            navigate({
              pathname: '/login'
            })
          }, 2000)
        } else {
          toast.error(otpresponses.message)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  return (
    <Layout>
      <ToastContainer />
      <div className='login-bg'>
        <div className='container'>
          <div className='row align-items-center position-relative justify-content-center'>
            <div className='col-lg-5 wow animate__animated animate__fadeInDown  animate__slow'>
              <div className='card-div p-5'>
                {/* <h2 className="text-white fw-bold">OTP</h2> */}
                {/* <p className="mt-3">Submit your OTP</p> */}
                <div className='i mb-3 form-div'>
                  {/* <label htmlFor="" className="">OTP <span>*</span></label> */}
                  <input
                    type='number'
                    onChange={e => {
                      setOtp(e.target.value)
                    }}
                    className='form-control'
                    placeholder='Enter Your OTP'
                    aria-label='User Name'
                    aria-describedby='basic-addon2'
                  />
                </div>
                <div className='mt-4'>
                  <Link
                    className='btn-1 w-100 text-center'
                    onClick={otpVerify}
                  >
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Otp
