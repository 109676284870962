import { Link } from 'react-router-dom'

function SubHeader () {
  return (
    <>
    <div className="col-lg-3 mt-23">
      <div className='page-leftsidebar '>
        <ul className='d-block text-decoration-none list-unstyled'>
          <li>
            <Link to='/newchallenge' className='text-decoration-none'>
              <div className='anim-button'>
                

                <i className='ico'>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='#fff'
                    className='bi bi-patch-plus-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.916 2.916l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.916 2.916l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.916-2.916l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.916-2.916l-.89.01-.622-.636zM8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0z' />
                  </svg>
                </i>
                <span>New Challenge</span>
              </div>
            </Link>
          </li>
          <li>
            {/* to='/accountdetails' */}
            <Link to='/dashboard' className='text-decoration-none'>
              <div className='anim-button'>
                

                <i className='ico'>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='#fff'
                    className='bi bi-house-door-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='M6.5 14.5v-3.505c0-.165.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z' />
                  </svg>
                </i>
                <span>Dashboard</span>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/payout' className='text-decoration-none'>
              <div className='anim-button'>
                

                <i className='ico'>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='#fff'
                    className='bi bi-credit-card-2-back-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z' />
                  </svg>
                </i>
                <span>Payouts</span>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/certificate' className='text-decoration-none'>
              <div className='anim-button'>     
                <i className='ico'>
                  {' '}
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-file-earmark-fill" viewBox="0 0 16 16">
                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z"/>
                  </svg>
                </i>
                <span>Certificates</span>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/invoice' className='text-decoration-none'>
              <div className='anim-button'>     
                <i className='ico'>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='#fff'
                    className='bi bi-credit-card-2-back-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z' />
                  </svg>
                </i>
                <span>Invoice</span>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/kycstepone' className='text-decoration-none'>
              <div className='anim-button'>     
                <i className='ico'>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='#fff'
                    className='bi bi-credit-card-2-back-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z' />
                  </svg>
                </i>
                <span>Profile</span>
              </div>
            </Link>
          </li>
        </ul>
      </div>
      </div>
    </>
  )
}

export default SubHeader
