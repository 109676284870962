
// styles
import '../../assets/FinishingUp.css'
// framer motion
import { motion as m } from 'framer-motion'

export default function FinishingUp({values, goto, couponList}) {
  const phaseList = ['Regular', 'Super']
  
  const container ={
    hidden: {
        opacity:0,
    },
    show: {
        opacity:1,
    },
  }

  // Filter out the applied coupons
  const appliedCoupons = couponList.filter(coupon => coupon.applied);

  // Reduce the applied discounts
  const totalDiscount = appliedCoupons.reduce((acc, coupon) => acc + parseInt(coupon.discount), 0);

  const plan = values.account_size

  let planName = phaseList[values.account_phase]  

  return (
    <m.div 
      className='finish-form'
      variants={container}
      initial='hidden'
      animate='show'
    >
      <h2>Finishing up</h2>
      <p>Double-check everything looks OK before confirming</p>
      <div className="bill-container">
        <div className="plan">
          <div>
            <strong>Learner : {planName} - ${plan}</strong>
            <button className='primary-btn btn-sm py-1 px-2' type='button' onClick={() => goto(1)}>Change</button>
          </div>
          <small>${values.account_price}</small>
        </div>
        {appliedCoupons.length >= 1 &&
          <div className="addOn">
            {
              appliedCoupons.map((item, index)=>(
                <div key={index}>
                  <small>Coupon Code : {item.coupon}</small>
                  <small>Discount : {item.discount}</small>
                </div>
              ))
            }
          </div>
        }
      </div>

      <div className="summary">
        <small>Total</small>
        <small> { values.account_price - totalDiscount } </small>
      </div>
    </m.div>
  )
}
