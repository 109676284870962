import img31 from '../assets/images/landing-page/logo-467x100.png'
import { useNavigate, Link } from 'react-router-dom'

function Header () {
  let navigate = useNavigate()
  const movePage = () => {
    navigate('/login')
  }
  const logout = async () => {
    localStorage.clear()
    navigate('/login')
  }
  return (
    <>
      <nav
        className=' menu-bar navbar navbar-expand-lg navbar-dark bg-dark sticky-top'
        aria-label='Offcanvas navbar large'
      >
        <div className='container-fluid'>
          <Link className='navbar-brand' style={{ cursor: 'pointer' }} to='/'>
            <img src={img31} alt='logo' width='180px' />
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='offcanvas'
            data-bs-target='#offcanvasNavbar2'
            aria-controls='offcanvasNavbar2'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div
            className='offcanvas offcanvas-end text-bg-dark'
            tabIndex='-1'
            id='offcanvasNavbar2'
            aria-labelledby='offcanvasNavbar2Label'
          >
            <div className='offcanvas-header'>
             
              <button
                type='button'
                className='btn-close btn-close-white'
                data-bs-dismiss='offcanvas'
                aria-label='Close'
              ></button>
            </div>
            <div className='offcanvas-body'>
              <ul className='navbar-nav justify-content-end flex-grow-1 pe-3'>
                <li className='nav-item'>
                  <Link className='nav-link' aria-current='page' to={ localStorage.getItem('token') != null ? '/newchallenge' : '/login' }>
                    New Challenge
                  </Link>
                </li>
                {localStorage.getItem('token') != null && (
                  <li className='nav-item'>
                    <Link className='nav-link' to='/dashboard'>
                      Dashboard
                    </Link>
                  </li>
                )}
                {localStorage.getItem('token') != null && (
                  <li className='nav-item'>
                    <Link className='nav-link' to='/certificate'>
                      Certificates
                    </Link>
                  </li>
                )}
                <li className='nav-item'>
                  <Link className='nav-link' to='/trading_rules'>
                    Trading Rules
                  </Link>
                </li>
                <li className='nav-item d-lg-none d-block'>
                  <Link className='nav-link' to='/payout'>
                    Payouts
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/faq'>
                    FAQ
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link' to='/contactus'>
                    Contact Us
                  </Link>
                </li>                
              </ul>
              <div>
                {localStorage.getItem('token') === null ? (
                  <button
                    className='primary-btn btn-sm py-1 px-2'
                    type='button'
                    onClick={() => {
                      movePage()
                    }}
                  >
                    Login
                  </button>
                ) : (
                  <button
                    className='primary-btn btn-sm py-1 px-2 mt-4 mt-lg-0'
                    type='button'
                    onClick={() => {
                      logout()
                    }}
                  >
                    Logout
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
